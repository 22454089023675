<template lang="html">
  <div class="">
    <div id="dropin-container">
    </div>

    <div class="text-center my-3">
      <a class='btn btn-primary' id='store-card' href='#'>Confirm subscription</a>
    </div>

  </div>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    account: { type: Object, required: true }
  },
  data() {
    return {
      nonce: ''
    }
  },
  mounted() {
    var that = this;
    var button = document.querySelector('#store-card');

    braintree.dropin.create({
      authorization: gon.client_token,
      container: '#dropin-container',
    }, function (createErr, instance) {
      button.addEventListener('click', function () {
        if (button.disabled) {
          return;
        }
        button.disabled = true;
        button.textContent = 'Loading ...'

        instance.requestPaymentMethod(function (error, payload) {
          if (error) {
            Toastr.error('There was error with your payment. ' + error.message + ' Please try again.', 'Payment error.');
            button.disabled = false;
            button.textContent = 'Go to checkout'
          } else {
            Toastr.success('Credit card stored, please continue to checkout.', 'Card verified.');
            that.nonce = payload.nonce
            that.submit()
          }
        });
      });
    });
  },
  methods: {
    submit() {
      if (!this.accountPersisted()) { return }

      var that = this;

      $.ajax({
        method: 'POST',
        data: {
          payment_method_nonce: that.nonce,
          terms: true
        },
        url: that.account.links.users_subscriptions + '.json',
        success: function(data) {
          Toastr.success('Successful subscription!');
          window.location = gon.redirect_to_on_complete
        },
        error: function(data) {
          Toastr.error(data.responseJSON.errors_full.join(), 'Error creating subscription');
        }
      });
    },
    accountPersisted() {
      if (!this.account.persisted) {
        Toastr.error('Error', 'Create your account first');
      }
      return this.account.persisted;
    }
  }
}
</script>

<style lang="css" scoped>
</style>
