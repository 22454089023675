<template>
  <transition name="slide-fade" v-on:after-leave="afterLeave">
    <div class="col-3" v-if="show">
      <div class="p-1 border border-light my-2 image-wrapper">
        <div class="icon-wrapper border" v-on:click="removeItem">
          <i class="icon fas fa-times"></i>
        </div>
        <img :src="item.variants.square_thumb">
        <div class="form-group string">
          <label class="form-control-label string">Alt text</label>
          <input class="form-control string" type="text" v-on:keyup="update" v-model="item.alt_text">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    item: {type: Object, required: true}
  },
  data: function () {
    return {
      show: true
    }
  },
  methods: {
    removeItem: function() {
      var that = this;
      var images = this.$parent.images;

      $.ajax({
        method: 'DELETE',
        url: that.item.links.self,
        success: function(res) {
          that.show = false
          Toastr.success('Image removed!');
        },
        error: function(res) {
          Toastr.error(res.responseJSON.errors_full.join(), 'Error removing image.');
        }
      })
    },
    afterLeave: function() {
      var images = this.$parent.realList;
      images.splice(images.indexOf(this.item), 1);
    },
    update() {
      $.ajax({
        method: 'PUT',
        url: this.item.links.self,
        data: { product_image: {alt_text: this.item.alt_text} },
        error: function(res) {
          Toastr.error(res.responseJSON.errors_full.join(), 'Error saving image.');
        }
      })
    }
  }
}
</script>

<style lang="css">
.image-wrapper {
  text-align: center;
  position: relative;
}

.image-wrapper img {
  max-width: 100%;
  width: auto;
}

.image-wrapper .icon-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fff;
  cursor: pointer;
  width: 22px;
}

</style>
