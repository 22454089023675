<template lang="html">
  <div id="hiw-modal-video" class="hiw-video-player">
    <video width="320" height="240" controls id="hiw-video">
      <source src="../../../../videos/hiw-video.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <span @click="closeModal">
    <i class="icon fas fa-times" ></i>
    </span>
  </div>
</template>

<script>
  export default {
    name: "HowItWorksVideoModal",
    props: {
      isShowedModal: {
        type: Boolean,
        default: false
      },
    },
    components: {
    },
    data() {
      return {
      }
    },
    methods: {
      closeModal(){
        $('#hiw-video').get(0).pause()
        $('#hiw-video').get(0).currentTime = 0;
        this.$emit('close')
      }
    }
  }
</script>