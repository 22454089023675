<template>
  <div>
    <div class="form-group">
      <label class="bmd-label-floating">Search collections</label>
      <input class="form-control" name="searchOccasions" type="text" v-on:keyup="search" ref="searchOccasions"></input>
    </div>
    <div class="list-group">
      <item v-for="item in items" :item="item" :key="item.id" v-bind:addpath="addpath" v-bind:createpath="createpath" />
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api"
import Item from './item';

export default {
  props: {
    sourcepath: {type: String, required: true},
    addpath: String,
    createpath: String
  },
  components: { Item },
  data: function() {
    return {
      items: []
    }
  },
  methods: {
    search() {
      var that = this;
      var term = event.target.value;
      that.items = [];

      if (term.length > 2) {
        $.get(that.sourcepath, {term: term}, function( data ) {
          that.items = JsonApi.deserialize(data).data
          that.items.push({id: null, name: term, persisted: false})
        })
      }
    }
  }
}
</script>
