import Products from "./products";

export default class extends Products {
  index() {
    super.index();

    $(".reviews-carousel").slick({
      arrows: false,
      dots: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 20000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $('.collections-carousel').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

  show() {
    super.index();
  }
}
