<template>
  <div>
    <sub-order v-for='sub_order in sub_orders'
      :key='sub_order.id'
      :sub_order='sub_order'
      @update-order-item='updateOrderItem'
      @review-modal='reviewModal'
      @show-review-modal='showReviewModal'
      @update-sub-order='updateSubOrder'>
    </sub-order>
    <div v-if='sub_orders.length ==0'>
      (No results.)
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from 'toastr';
import SubOrder from './sub_order'
import OrderItem from './order_item'

export default {
  components: { SubOrder, OrderItem },
  props: {
    sub_orders: { type: Array, required: true }
  },
  methods: {
    showReviewModal(review) { this.$emit('show-review-modal', review) },
    reviewModal(path, id) { this.$emit('review-modal', path, id) },
    updateOrderItem(event) { this.$emit('update-order-item', event) },
    updateSubOrder(event) { this.$emit('update-sub-order', event) }
  }
}
</script>
