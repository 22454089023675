import Visitors from './visitors';
import Posts from './posts';
import Profile from './profile';
import Products from './products';
import Occasions from './occasions';
import Collections from './collections';
import Checkout from './checkout';
import Subscriptions from './subscriptions';

import UsersOrders from './users/orders';
import UsersSubscriptions from './users/subscriptions';

import VendorsProfile from './vendors/profile';

import VendorsOrders from './vendors/orders';

import VendorsProducts from './vendors/products';
import VendorsProductsImages from './vendors/products/images';
import VendorsProductsVariants from './vendors/products/variants';
import VendorsProductsUnits from './vendors/products/units';
import VendorsProductsUnitsAbsences from './vendors/products/absences';

export default {
  Visitors,
  Posts,
  Profile,
  Products,
  Occasions,
  Collections,
  Checkout,
  Subscriptions,

  UsersOrders,
  UsersSubscriptions,

  VendorsProfile,

  VendorsOrders,

  VendorsProducts,
  VendorsProductsImages,
  VendorsProductsVariants,
  VendorsProductsUnits,

  VendorsProductsUnitsAbsences,
};

// Defaults for all controllers
$('.filter-categories a.icon-expand').on('click', function() {
  var icon =
    $(this)
      .find('svg')
      .data('icon') == 'plus'
      ? 'minus'
      : 'plus';
  $(this)
    .find('svg')
    .attr('data-icon', icon);
});
