export default class Helpers {
  sameHeight(selector) {
    // set items same height
    var items = $(selector)
    var maxHeight = Math.max.apply(null, items.map(function (){
      return $(this).height();
    }).get());
    items.map(function (){
      $(this).height(maxHeight);
    });
  }
}
