import Vue from "vue/dist/vue.esm";
import JsonApi from "../../vendor/json-api";
import * as Toastr from 'toastr';
import draggable from 'vuedraggable';

import Dropzone from "../../components/images/dropzone";
import ImageItem from "../../components/images/item";

export default class AdminProductsImages {
  index() {
    var app = new Vue({
      el: '#product-images-app',
      components: { ImageItem, Dropzone, draggable },
      data: {
        images: JsonApi.deserialize(gon.payload).data,
      },
      methods: {
        onEnd: function(event) {
          var positions = [];
          $.each(this.images, function( index, value ) {
            value.position = index
            positions.push({id: value.id, position: index});
          });

          $.ajax({
            method: 'POST',
            url: $(this.$el).data('positions-path'),
            data: {
              positions: positions
            },
            success: function(res) {
              Toastr.success('Positions saved!');
            },
            error: function(res) {
              Toastr.error('Error saving positions.');
            }
          })
        }
      }
    });
  }
}
