<template>
  <div>
    <transition name="slide-fade" v-on:after-leave="afterLeave">
      <div v-if="show">

        <h5>Variant #{{item.id}}</h5>
        <div class="row">
          <div v-for="option_key in item.product.data.selected_options" class="col-sm-6 col-md-4">
            <div class="form-group string mb-0">
              <label class="form-control-label string text-capitalize" :for="'variant_' + item.id + '_option_' + option_key ">{{option_key}}</label>
              <input class="form-control string" type="text" v-model="item.options[option_key]" :name="'option['+ option_key +']'" :id="'variant_' + item.id + '_option_' + option_key " v-on:keyup.enter="saveItem" v-bind:class="{ 'is-invalid': errors['options_'+option_key] }">
              <div v-if="errors['options_'+option_key]" class="invalid-feedback">{{ errors['options_'+option_key].join(', ') }}</div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4 d-flex pt-2" v-bind:class="{ 'pb-4': hasErrors }">
            <button type="button" class="btn btn-outline btn-outline-secondary btn-default mt-auto mr-1" v-on:click='saveItem'>Save</button>
            <button type="button" class="btn btn-outline btn-danger mt-auto" v-on:click='removeItem'>Remove</button>
          </div>
        </div>
        <hr>

      </div>
    </transition>
  </div>

</template>

<script>
import JsonApi from "../../../vendor/json-api"
import * as Toastr from 'toastr';

export default {
  props: {
    item: Object,
    createpath: String
  },
  data: function () {
    return {
      show: true,
      errors: {}
    }
  },
  computed: {
    hasErrors: function () {
      return Object.keys(this.errors).length != 0
    }
  },
  methods: {
    saveItem() {
      if (this.item.persisted) {
        this.updateItem();
      } else {
        this.createItem()
      }
    },
    createItem() {
      var that = this;
      $.ajax({
        method: 'POST',
        data: {
          variant: {
            options: that.item.options
          }
        },
        url: that.createpath,
        success: function(data) {
          var variants = that.$parent.variants;
          variants.splice(variants.indexOf(that.item), 1)
          var deserialized = JsonApi.deserialize(data)
          variants.push(deserialized)
          that.errors = {}
          Toastr.success('Variant #' + deserialized.id + ' saved!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving variant #' + that.item.id);
        }
      });
    },
    updateItem() {
      var that = this;
      $.ajax({
        method: 'PUT',
        data: {
          variant: {
            options: that.item.options
          }
        },
        url: that.item.links.self,
        success: function(data) {
          that.errors = {}
          Toastr.success('Variant #' + that.item.id + ' saved!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving variant #' + that.item.id);
        }
      });
    },
    removeItem() {
      var that = this;
      if (!that.item.persisted) {
        that.show = false;
        return;
      }

      $.ajax({
        method: 'DELETE',
        url: that.item.links.self,
        success: function(data) {
          that.show = false
          that.errors = {}
          Toastr.success('Variant #' + that.item.id + ' removed!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error removing variant #' + that.item.id);
        }
      });
    },
    afterLeave: function() {
      var variants = this.$parent.variants;
      variants.splice(variants.indexOf(this.item), 1)
    }
  }
}
</script>
