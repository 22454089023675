import Vue from "vue/dist/vue.esm"
import JsonApi from "../../../../vendor/json-api"

import AdminProductsVariants from "../../../../admin/controllers/products/variants";


export default class VendorsProductsVariants extends AdminProductsVariants {
  index() {
    super.index();
  }
}
