import Vue from "vue/dist/vue.esm";
import JsonApi from "../../vendor/json-api";

import AccountProfile from '../components/account/profile';
import AccountVerification from '../components/account/verification';
import AccountAddress from '../components/account/address';

export default class Profile {
  account() {
    var app = new Vue({
      el: '#profile-form',
      propsData: {
        new_address_path: String
      },
      components: { AccountProfile, AccountVerification, AccountAddress },
      data: {
        user: JsonApi.deserialize(gon.payload.user),
        account: JsonApi.deserialize(gon.payload.account),
        trulioo_verification: JsonApi.deserialize(gon.payload.trulioo_verification),
        shipping_addresses: JsonApi.deserialize(gon.payload.shipping_addresses).data,
        billing_addresses: JsonApi.deserialize(gon.payload.billing_addresses).data,
        new_address_path: gon.payload.new_address_path
      },
      computed: {
        not_verified: function() {
          if (this.trulioo_verification.status != 'match' || this.account.confirmed == false || this.user.phone_verified == false) {
            return true;
          } else {
            return false;
          }
        }
      },
      methods: {
        addAddress: function(address_type, $event) {
          var that = this;

          $.ajax({
            method: 'GET',
            url: that.new_address_path,
            success: function(data) {
              var address = JsonApi.deserialize(data);
              address.address_type = address_type;

              if (address_type == 'shipping') {
                that.shipping_addresses.push(address);
              } else {
                that.billing_addresses.push(address);
              }
            },
            error: function(data) {
              Toastr.error(data.responseJSON.errors, 'Error adding address');
            }
          });
        },
        onSaveAddress(formAddress, address) {
          var addresses = address.address_type == 'shipping' ? this.shipping_addresses : this.billing_addresses;
          addresses.splice(addresses.indexOf(formAddress), 1);
          addresses.push(address);
        },
        onRemoveAddress(address) {
          var addresses = address.address_type == 'shipping' ? this.shipping_addresses : this.billing_addresses;
          if (this.persisted) {
            addresses.splice(addresses.indexOf(address), 1);
          }
        }
      }
    })
  }
}
