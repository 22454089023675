<template>
  <div>
    <form class="dropzone" :action="createpath"></form>
  </div>
</template>

<script>
import JsonApi from '../../../vendor/json-api';
import * as Toastr from 'toastr';
import 'dropzone/dist/dropzone';

export default {
  props: {
    createpath: { type: String, required: true },
  },
  mounted: function() {
    var that = this;

    $('.dropzone').dropzone({
      paramName: 'product_image[file]',
      previewsContainer: false,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      dictDefaultMessage:
        'Drag a image files here or browse for upload. <br /> You can upload single or multiple files. <p class="text-muted mt-2"> Images must be on clear white background, without additional white spaces around the image, JPG format, min 500 X 500 pixels.</p>',
      success: function(file, response) {
        var deserialized = JsonApi.deserialize(response);
        that.$parent.images.push(deserialized);
        Toastr.success('Image added successfull');
      },
      error: function(file, xhr, data) {
        var response = JSON.parse(data.response);
        Toastr.error(response.errors_full.join(), 'Error adding image');
      },
    });
  },
};
</script>

<style lang="css">
form.dropzone {
  border: 2px dashed rgba(62, 142, 247, 0.5);
  margin-bottom: 30px;
}

form.dropzone:hover {
  border-color: rgb(62, 142, 247);
}
</style>
