import _ from "lodash"

class GtmEvents {

  async trackCartEvents(event_name) {
    const data = await this.fetchCurrentCartData()
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event_name,
      ecommerce: {
        value: data.value,
        items: _.isEmpty(data.items) ? undefined : data.items
      },
    });
  }

  trackItemEvent(event_name, { product_data = {}, order_data = {} } = {}) {
    const ecommerce_item_data = _.isEmpty(product_data) ? this.serializeOrderData(order_data) : this.serializeProductData(product_data)
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event_name,
      ecommerce: ecommerce_item_data
    });
  }
  
  serializeOrderData(data) {
    return {
      value: parseFloat(data.order_item.price),
      items: [
        {
          item_id: data.order_item.product_id,
          item_name: data.order_item.name,
          currency: "USD",
          item_brand: data.order_item.brand,
          item_category: data.order_item.product.categories,
          price: parseFloat(data.order_item.price),
          quantity: 1
        }
      ]
    }
  }
  
  serializeProductData(product_data) {
    return {
      value: parseFloat(product_data.attributes.price),
      items: [
        {
          item_id: product_data.id,
          item_name: product_data.attributes.title,
          currency: "USD",
          item_brand: product_data.attributes.properties.brand,
          item_category: product_data.attributes.categories,
          price: parseFloat(product_data.attributes.price),
          quantity: 1
        }
      ]
    }
  }
  
  async fetchCurrentCartData() {
    const response = await fetch('/my-account/current_carts')
    const data = await response.json()
    return data;
  }
}

export default new GtmEvents()