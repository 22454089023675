<template>
  <div class="modal fade" id="modal-instructions" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">close x</span>
          </button>
        </div>
        <div class="modal-body">

          <h4 class="text-center mb-4">Resend confirmation instructions</h4>

          <div class="text-center p-4" v-if="status === 'complete'">
            <h5>Success!</h5>
            <p>
              You will receive an email with instructions
              <br>
              for how to confirm your email address in a few minutes.
            </p>
          </div>

          <div v-if="status === 'pending'">
            <FacebookButton
              :modal-name="'instructions'"
              :with-icon=false
            />
            <div class="my-3 text-center">
              <h5>or</h5>
            </div>
            <div class="form-group">
              <label for="instructions-email">Email address</label>
              <input type="email" class="form-control" id="instructions-email" placeholder="Enter email" v-model="email" v-bind:class="{ 'is-invalid': errors.email }" @keyup.enter="resendInstructions">
              <div v-if="errors.email" class="invalid-feedback">{{ errors.email.join(', ') }}</div>
            </div>

            <button type="submit" class="btn btn-primary btn-block" @click="resendInstructions">Resend confirmation instructions</button>
            <hr>
            <div class="my-3">
              <a href="javascript:;" @click="showLoginModal">Log in</a>
              <br>
              <a href="javascript:;" @click='showRegisterModal'>Create account</a>
              <br>
              <a href="javascript:;" @click='showPasswordModal'>Forgot your password?</a>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from 'toastr';
import FacebookButton from './facebook_button'
export default {
  components: {
    FacebookButton: FacebookButton
  },
  props: {
    path: { type: String, required: true }
  },
  data: function() {
    return {
      email: '',
      errors: {},
      status: 'pending'
    }
  },
  methods: {
    resendInstructions() {
      var that = this;

      $.ajax({
        method: 'POST',
        data: {account: {
          email: that.email
        }},
        url: that.path,
        success: function(data) {
          that.status = 'complete'
          Toastr.success('Confirmation instructions sent successfull!', 'Success');
          setTimeout(function(){
            location.reload()
          }, 2000);
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.error, 'Authentication error');
        }
      })
    },
    showLoginModal() {
      $('#modal-password').modal('hide')
      $('#modal-login').modal('show')
    },
    showRegisterModal() {
      $('#modal-password').modal('hide')
      $('#modal-register').modal('show')
    },
    showPasswordModal() {
      $('#modal-login').modal('hide')
      $('#modal-password').modal('show')
    }
  }
}
</script>
