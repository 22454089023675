<template>
  <div>
    <div class="focus-title my-30">
      <h1 class="main-text mb-5 text-left">Rent By Category</h1>
    </div>

    <div class="row row-categories">
      <div class="col-md-6">
        <a href="/rent-jewelry?type=necklaces" class="text-center d-block">
          <img src="~images/app/products/index/categories/necklace.webp" />
        </a>

        <div class="focus-title my-10">
          <a href="/rent-jewelry?type=necklaces">
            <h1 class="main-text mb-10 text-center">
              RENT NECKLACES
            </h1>
            <p class="sub-text text-center">
              From dainty layered pieces to bold statement 
              <br />
              necklaces available to borrow.
            </p>
          </a>
        </div>

        <div class="text-center py-15">
          <a class="h5" href="/rent-jewelry?type=necklaces">
            Browse Necklaces For Rent
          </a>
        </div>
      </div>

      <div class="col-md-6">
        <a href="/rent-jewelry?type=earrings" class="text-center d-block">
          <img src="~images/app/products/index/categories/earrings.webp" />
        </a>

        <div class="focus-title my-10">
          <a href="/rent-jewelry?type=earrings">
            <h1 class="main-text mb-10 text-center">
              RENT EARRINGS
            </h1>
            <p class="sub-text text-center">
              Discover a selection of drops, hoops, and more
              <br />
              for your next special occasion.
            </p>
          </a>
        </div>

        <div class="text-center py-15">
          <a class="h5" href="/rent-jewelry?type=earrings">
            Browse Earrings For Rent
          </a>
        </div>
      </div>

      <div class="col-md-6">
        <a href="/rent-jewelry?type=bracelets" class="text-center d-block">
          <img src="~images/app/products/index/categories/bracelets.webp" />
        </a>

        <div class="focus-title my-10">
          <a href="/rent-jewelry?type=bracelets">
            <h1 class="main-text mb-10 text-center">
              RENT BRACELETS
            </h1>
            <p class="sub-text text-center">
              Experiment with our variety of bracelets. 
              <br />
              Mix, match, and elevate your outfit.
            </p>
          </a>
        </div>

        <div class="text-center py-15">
          <a class="h5" href="/rent-jewelry?type=bracelets">
            Browse Bracelets For Rent
          </a>
        </div>
      </div>

      <div class="col-md-6">
        <a href="/rent-jewelry?type=rings" class="text-center d-block">
          <img src="~images/app/products/index/categories/rings.webp" />
        </a>

        <div class="focus-title my-10">
          <h1 class="main-text mb-10 text-center">
            RENT RINGS
          </h1>
          <p class="sub-text text-center">
            Express your unique style with our wide range 
            <br />
            of statement and stackable rings.
          </p>
        </div>

        <div class="text-center py-15">
          <a class="h5" href="/rent-jewelry?type=rings">
            Browse Rings For Rent
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCategories',
}
</script>

<style scoped>
  .row-categories img {
    height: 390px;
  }
</style>