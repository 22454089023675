<template lang="html">
  <div class="">
    <div class="step-header">
      all set. confirm your subscription trial.
    </div>
    <div class="row">
      <div class="col-sm-4 col-md-5">
        <img src="~images/app/subscriptions/step-4.webp" />
      </div>

      <div class="col-sm-8 col-md-7">
        <h3 class="fieldset-label">Add Billing Information</h3>
        <address-form :address='address' @set-address='setAddress' v-if="!account.use_shipping_as_billing_address && edit" />
        <div v-else>
          <p>
            {{ addressShipping.street }}, {{ addressShipping.number }}
            <br>
            {{ addressShipping.city }}, {{ addressShipping.state }}, {{ addressShipping.zip }}</p>
          <a href="javascript:;" @click="enterEdit" class="edit-btn">Edit</a>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="use_shipping_as_billing_address" v-model="account.use_shipping_as_billing_address">
          <label class="form-check-label" for="use_shipping_as_billing_address">Billing address is same as shipping address</label>
        </div>

        <h3 class="fieldset-label">Add Payment Information</h3>
        <subscription-form :account='account'  />
      </div>

    </div>
  </div>
</template>

<script>
import AddressForm from "./address_fields";
import SubscriptionForm from "./subscription";

export default {
  props: {
    address: { type: Object, required: true },
    addressShipping: { type: Object, required: true },
    account: { type: Object, required: true }
  },
  data() {
    return {
      edit: !this.address.persisted
    }
  },
  components: { AddressForm, SubscriptionForm },
  methods: {
    setAddress(data) {
      this.edit = !data.persisted
      this.$emit('set-address', data)
    },
    nextStep() {
      this.$emit('next-step', '4')
    },
    enterEdit() {
      this.edit = true;
      this.account.use_shipping_as_billing_address = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.fieldset-label {
  background: #EDEDED;
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.edit-btn {
  text-decoration: underline;
  margin-bottom: 10px;
}
</style>
