import Vue from "vue/dist/vue.esm"
import JsonApi from "../../../../vendor/json-api"

import VendorsProductsAbsence from "../../../components/vendors/products/absences"
import * as Toastr from 'toastr';

export default class VendorsProductsAbsences {
  index() {
    new Vue({
      el: '#vendor-absence-form',
      propsData: {
        absence_create_path: String
      },
      components: {
        VendorsProductsAbsence
      },
      data: {
        absence_create_path: gon.absence_create_path,
        absences: JsonApi.deserialize(gon.payload).data,
        unavailable_dates: gon.unavailable_dates
      },
      methods: {
        deleteAbsence(id, index) {
          let context = this
          if(confirm('Are you sure?')) {

            $.ajax({
              method: 'DELETE',
              url: this.absence_create_path + '/' + id,
              success(response) {

                Toastr.success('Successfully remove maintenance', 'Success');
                context.absences.splice(index, 1)
                context.unavailable_dates = response.unavailable_dates;
              },
              error(response) {
                var errors = response.responseJSON.errors_full ? response.responseJSON.errors_full : response.responseJSON.errors
                Toastr.error(errors.join(), 'maintenance not deleted');
              }
            })
          }
        }
      }
    })
  }
}
