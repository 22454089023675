<template>
  <div>
      <div class="row">
        <div class="col-6">
      <flat-pickr v-model="date" :config="config" placeholder="Select a date" @on-change='setRange'></flat-pickr>
        </div">
        <div class="col-6">
          <div class="form-group">
            <label for="account-first-name">Notes</label>
            <textarea v-model='new_absence.notes' rows=10 type="text" class="form-control" id="account-first-name" placeholder="Notes" />
            <div class="invalid-feedback"></div>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-12'>
        <div class='text-center'>
          <button type='submit' class='btn btn-primary' @click='submitForm'> Add maintenance </button>
      </div>
      </div>
      </div>
    </div>
</template>


<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import * as Toastr from 'toastr';
import JsonApi from "../../../../vendor/json-api"

export default {
  props: {
    absence_create_path: {type: String, required: true},
    unavailable_dates: {type: Array, required: true}
  },
  components: {
    flatPickr
  },
  data() {
    return {
      new_absence: {
        start_date: '',
        end_date: '',
        notes: ''
      },
      date: Date.new,
      config: {
        inline: true,
        altFormat: "F j, Y",
        altInput: true,
        mode: 'range',
        minDate: 'today',
        disable: this.unavailable_dates,
        defaultDate: null
      }
    }
  },
  watch: {
    unavailable_dates: {
      handler() {
        this.config.disable = this.unavailable_dates
      }
    }
  },
  methods: {
    setRange(selectedDates, dateStr, instance) {
      let dates = dateStr.split(' to ')

      this.new_absence.start_date = dates[0]
      this.new_absence.end_date = dates[1]
    },
    getAllDays(start, end) {
      if (!(start instanceof Date) || !(end instanceof Date)) { throw "Invalid date formats. Please provide Date objects."; }
      if (start > end) { throw "Start date is bigger than end date. Please set correct values"; }

      var array = [];
      var tmpStart = new Date(start);

      array.push(start);

      while(tmpStart < end) {
        array.push(tmpStart); tmpStart = new Date(tmpStart.setDate(tmpStart.getDate() + 1)); } return array; },

    submitForm() {
      let context = this

      $.ajax({ method: 'POST',
        data: {
          absence: this.new_absence,
        },
        success(response) {

          context.$emit('absence-added', JsonApi.deserialize(response))
          Toastr.success('Maintenance from ${context.date} added to this unit', 'Success');

          let datesToDisable = context.getAllDays(new Date(context.new_absence.start_date), new Date(context.new_absence.end_date))

          context.config.disable = context.config.disable.concat(datesToDisable)

          // clear
          context.new_absence = {
            start_date: '',
            end_date: '',
            notes: ''
          }
          context.date = null
        },
        error(response) {
          Toastr.error(response.responseJSON.errors_full.join(), 'Maintenance not added');
        }
      })
    }


  }
}
</script>
