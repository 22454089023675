<template>
  <div class="order-item">
    <div class="row">
      <div class="col-md-2 mb-3">
        <a :href='product.links.public' class="a-image" target="_blank">
          <img :src='order_item.image_urls.thumb'>
        </a>
      </div>
      <div class="col-md-4">
        <a :href='product.links.public' class='a-title'>
          {{product.title}}
        </a>
        <div class="sub text-black-50">
          {{product.short_description}}
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <strong>Rental price</strong>
            <div class="sub text-black-50">
              {{ order_item.rental_price_formatted }} / per day
            </div>
          </div>
          <div class="col-6">
            <strong>Market price</strong>
            <div class="sub text-black-50">
              {{ order_item.market_price_formatted }}
            </div>
          </div>
          <div class="col-6 purchase-option">
            <strong>Purchase Option</strong>
            <div class="sub text-black-50">
              {{ this.purchase }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 mb-3">
        <div>
          <strong>Vendor</strong>
        </div>
        {{ product.vendor.data.name }}
      </div>
      <div class="col-md-4 text-center">
        <div v-if="order_item.status == 'cancelled'" >
          <span class="badge badge-grey px-5 py-3">Cancelled</span>
        </div>
        <button v-else-if='reviewPersisted' class="btn btn-primary" @click='showReview' data-toggle='modal' data-target='#show-modal-review'>See your review</button>
        <button v-else @click='reviewModal' class='btn btn-primary' data-toggle='modal' data-target='#modal-review'>Review this item</button>
        <button v-if="isOrderItemPending" @click="cancelItem" class="btn btn-primary">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from "toastr";

export default {
  props: {
    order_item: {type: Object, required: true},
  },
  data() {
    return {
      product: this.order_item.product.data,
      purchase: this.order_item.product.data.purchase_option
    }
  },
  computed: {
    reviewPersisted() {
      return !_.isUndefined(this.order_item.user_review.data) && this.order_item.user_review.data.persisted
    },
    isOrderItemPending() {
      return this.order_item.status == 'pending';
    }
  },
  methods: {
    reviewModal() {
      this.$emit('review-modal', this.order_item.links.user_review, this.order_item.id)
    },
    showReview() {
      this.$emit('show-review-modal', this.order_item.user_review.data)
    },
    cancelItem() {
      let that = this;
      if(confirm(`Are you sure you want to cancel this order item? This action can\'t be undone. You'll receive ${this.order_item.store_credit_formatted} in store credit.`)) {
        $.ajax({
          url: this.order_item.links.cancel,
          method: "PATCH",
          data: {
            order_item: { status: "cancelled" },
          },
          success(response) {
            that.order_item.status = "cancelled";
            if(response.status == "cancelled") {
              that.$emit('order-cancelled', JsonApi.deserialize(response))
            }
            Toastr.success("Item has been cancelled");
          },
          error(response) {
            Toastr.error(
                response.responseJSON.errors_full.join(),
                "Item was not cancelled"
            );
          },
        });
      }
    }
  }
}
</script>

<style scoped>
.purchase-option {
  margin-top: 1em;
}
</style>
