<template>
  <div
    class="modal fade"
    id="modal-password"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span @click="closeModal" aria-hidden="true">X</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="text-center mb-2">Forgot your password?</h4>
          <hr />
          <div class="text-center p-4" v-if="status === 'complete'">
            <h5>Success!</h5>
            <p>
              You will receive an email with instructions
              <br />
              on how to reset your password in a few minutes.
            </p>
          </div>

          <div v-if="status === 'pending'">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="password-email"
                placeholder="Enter email"
                v-model="email"
                v-bind:class="{ 'is-invalid': errors.email }"
                @keyup.enter="resetPassword"
                @keyup="clearErrors"
              />
              <div v-if="errors.email" class="invalid-feedback">
                {{ errors.email.join(", ") }}
              </div>
            </div>
            <div class="button-container">
              <button
                type="submit"
                class="send-password-button"
                @click="resetPassword"
              >
                SEND NEW PASSWORD
              </button>
            </div>
            <div class="my-2 text-center">
              <h5>or</h5>
            </div>
            <div class="signup-method-wrapper">
              <FacebookButton
                  :modal-name="'password'"
                  :with-icon=true
              />
              <a
                href="/accounts/auth/google_oauth2"
                class="btn btn-block google-btn"
                data-method="post"
                rel="nofollow"
              >
                <div class="google-ico"></div>
                SIGN UP WITH GOOGLE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper" @click="closeModal"></div>
  </div>
</template>

<script>
import * as Toastr from "toastr";
import FacebookButton from './facebook_button'
export default {
  components: {
    FacebookButton: FacebookButton
  },
  props: {
    path: { type: String, required: true },
  },
  data: function () {
    return {
      email: "",
      errors: {},
      status: "pending",
    };
  },
  methods: {
    resetPassword() {
      var that = this;
      $.ajax({
        method: "POST",
        data: {
          account: {
            email: that.email,
          },
        },
        url: that.path,
        success: function (data) {
          that.status = "complete";
          Toastr.success("Password reset successfull!", "Success");
          setTimeout(function () {
            location.reload();
          }, 2000);
        },
        error: function (data) {
          that.errors = data.responseJSON.errors;
          Toastr.error(data.responseJSON.error, "Authentication error");
        },
      });
    },
    closeModal() {
      $("#modal-password").modal("hide");
      this.errors = {};
      this.email = "";
    },
    clearErrors() {
      this.errors = {};
    },
  },
};
</script>


<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 900;
}
.modal-dialog {
  border: 5px solid black;
  border-radius: 10px;
  z-index: 1000;
  @media (max-width: 560px) {
    width: fit-content;
    margin: 0 auto;
  }
}
.modal-header {
  button:hover {
    color: black;
    opacity: 0.7;
  }
}
.modal-content {
  h4 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 26px;
    color: #000000;
    margin: 0 auto;
    @media (max-width: 813px) {
      font-size: 24px;
    }
  }
  h5 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  hr {
    height: 1px;
    border-bottom: 1px solid black;
    width: 55%;
    margin-bottom: 50px;
  }
  input,
  .invalid-feedback {
    width: 80%;
    margin: 0 auto;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .send-password-button {
      width: 230px;
      height: 34px;
      background: #343233;
      color: white;
      font-family: Utile;
      font-size: 14px;
      border: none;
      &:hover {
        background: #7e7d7d;
      }
      @media (max-width: 813px) {
        width: 179px;
        height: 30px;
        font-size: 11px;
      }
    }
  }
}
::v-deep .fb-ico,
.google-ico {
  width: 24px;
  height: 22px;
  background: url("~images/app/register/fb-icon-white.svg");
  background-position: center;
  background-size: cover;
  margin-right: 0.5em;
  @media (max-width: 813px) {
    margin-right: 8px;
    height: 20px;
    width: 22px;
  }
}
.google-ico {
  background: url("~images/app/register/google-icon-white.svg");
  background-position: center;
  background-size: cover;
}
.google-btn,
.fb-btn {
  width: 230px;
  height: 34px;
  background: #817f7f;
  color: white;
  text-align: center;
  font-family: Utile;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  margin: 0 auto 15.5px;
  @media (max-width: 813px) {
    width: 179px;
    height: 30px;
    font-size: 11px;
  }
}
.google-btn {
  margin-bottom: 38px;
  @media (max-width: 813px) {
    margin-bottom: 20px;
  }
}
</style>