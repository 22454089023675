<template lang="html">
  <div class="subscriptions-wizard">
    <ul class="nav nav-tabs" id="subscriptions-steps" role="tablist" v-if="canJoinSubscription">
      <li class="nav-item">
        <a class="nav-link" :class='{active: step == 1}' @click="changeStep(1)" id="step-1" data-toggle="tab" href="#step-1" role="tab" aria-controls="step-1" aria-selected="true">Step 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class='{active: step == 2}' @click="changeStep(2)" id="step-2" data-toggle="tab" href="#step-1" role="tab" aria-controls="step-2" aria-selected="true">Step 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class='{active: step == 3}' @click="changeStep(3)" id="step-3" data-toggle="tab" href="#step-1" role="tab" aria-controls="step-3" aria-selected="true">Step 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class='{active: step == 4}' @click="changeStep(4)" id="step-4" data-toggle="tab" href="#step-4" role="tab" aria-controls="step-4" aria-selected="true">Step 4</a>
      </li>

    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show step-info" :class='{active : step == 1}' id="step-1" role="tabpanel" aria-labelledby="step-1">
        <step-info @next-step='changeStep' />
      </div>

      <div class="tab-pane fade show" :class='{active : step == 2}' id="step-2" role="tabpanel" aria-labelledby="step-2">
        <step-account :register-path='registerPath' :account='account' :sitekey='sitekey' @set-account="setAccount"/>
      </div>

      <div class="tab-pane fade show" :class='{active : step == 3}' id="step-3" role="tabpanel" aria-labelledby="step-3">
        <step-shipping :address='shippingAddress' @set-address='setAddress' @next-step='changeStep' />
      </div>

      <div class="tab-pane fade show" :class='{active : step == 4}' id="step-4" role="tabpanel" aria-labelledby="step-4">
        <step-billing :address='billingAddress' :address-shipping='shippingAddress' :account='account' @set-address='setAddress' />
      </div>

    </div>
  </div>
</template>

<script>
import StepInfo from './steps/info';
import StepAccount from './steps/account';
import StepShipping from './steps/shipping';
import StepBilling from './steps/billing';
import * as Toastr from 'toastr';

export default {
  props: {
    account: { type: Object, required: true },
    shippingAddress: { type: Object, required: true },
    billingAddress: { type: Object, required: true },
    registerPath: { type: String, required: true },
    sitekey: { type: String, required: true}
  },
  components: { StepInfo, StepAccount, StepShipping, StepBilling },
  data() {
    return {
      step: 1
    }
  },
  mounted() {
    var that = this;
    if (!this.canJoinSubscription) {
      Toastr.info('You\'ve already joined our subscription plan. You will be redirected to subscription page.', 'Already Subscribed');
      setTimeout(function(){
        window.location = that.account.links.users_subscriptions
      }, 2000)
    }
  },
  computed: {
    canJoinSubscription() {
      return !this.account.braintree_subscriber
    }
  },
  methods: {
    changeStep(toStep) {
      if (this.canJoinSubscription) {
        this.step = toStep;
      }
    },
    setAccount(data) {
      this.$emit('set-account', data);
      this.step = 3;
    },
    setAddress(data) {
      this.$emit('set-address', data);
    }
  }
}
</script>

<style lang="scss">
@import '~stylesheets/app/vars';

.subscriptions-wizard {

  .nav.nav-tabs {
    margin-bottom: 6px;
    li.nav-item {
      width: 25%;
      a {
        border: none;
        background: #EDEDED;
        color: #000;
        text-transform: uppercase;
        text-align: center;

        &.active {
          color: #fff;
          background: #000;
        }

        @media (max-width: 440px) {
          font-size: 12px;
          padding: 8px 16px;
        }

        @media (max-width: 440px) {
          font-size: 10px;
        }
      }
    }
  }

  .tab-content {
    border: none;
    padding: 0;

    .step-header {
      padding: 10px;
      font-size: 20px;
      font-family: $font-abril;
      letter-spacing: 0.4px;
      border: 1px solid #000;
      text-align: center;
      margin-bottom: 6px;
      line-height: 1.2em;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      @media (max-width: 450px) {
        font-size: 12px;
      }
    }

    .step-info {
      img {
        width: 100%;
      }
    }
  }
}
</style>
