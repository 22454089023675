<template lang="html">
  <div class="mb-3">
    <div v-if="shopping_mode != 'subscription'" class="form-inline">
      <div class="input-group mb-0 flex-grow-1" id="promo-input-btn">
        <input
          v-model="code"
          type="text"
          name="coupon_code"
          class="form-control"
          placeholder="Enter promo code"
        />
        <div class="input-group-append">
          <button class="btn promo-btn" @click="submit">Apply</button>
        </div>
      </div>
    </div>

    <h4 v-if="subscriptionDiscountMsg" class="my-3 text-center">
      {{ subscriptionDiscountMsg }}
    </h4>
  </div>
</template>

<script>
import * as Toastr from "toastr";
import JsonApi from "../../../vendor/json-api";
import EventBus from "../../event_bus";

export default {
  props: {
    reload: { type: Boolean, default: false },
    path: { type: String, required: true },
  },
  data() {
    return {
      code: "",
      subscriptionDiscountMsg: null,
    };
  },
  computed: {
    shopping_mode() {
      return gon.shopping_mode;
    },
  },
  methods: {
    cartReload(coupon) {
      EventBus.$emit('couponDivReload', coupon);
    },
    submit() {
      if (this.code == "") {
        Toastr.error("Please enter discount code!");
        return;
      }
      var context = this;
      $.ajax({
        method: "POST",
        data: { code: this.code },
        url: this.path,
        success: function (data) {
          if (data.type && data.type == "subscription") {
            context.displaySubscriptionDiscount(data);
          } else {
            context.displayCartDiscount(data);
          }
          const discountedPrice =
            data.data.attributes.rental_discount_formatted;
          const finalPrice = data.data.attributes.rental_end_price_formatted;
          context.$emit(
            "updateCoupon",
            context.code,
            discountedPrice,
            finalPrice
          );
          Toastr.success("Discount code applied successfully");
          context.cartReload({
            code: context.code,
            discountedPrice: discountedPrice,
            finalPrice: finalPrice
          });
        },
        error: function (data) {
          Toastr.error(
            data.responseJSON.errors_full.join(),
            "Discount code not applied"
          );
        },
      });
    },
    displayCartDiscount(data) {
      if ($(".col-price").length <= 0) {
        return;
      }
      var order = JsonApi.deserialize(data);
      $(".col-price .value del").html(order.rental_price_formatted);
      $(".col-price .value span.amount").html(order.rental_end_price_formatted);
      $(".col-price .value .applied-coupon").html(
        "Dicount code: <strong>" +
          order.coupon.data.code +
          "</strong> <a href=" +
          order.links.remove_coupon +
          " class='fs-10'>remove</a>"
      );
    },
    displaySubscriptionDiscount(data) {
      this.subscriptionDiscountMsg = data.message;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: 40px;
}
button {
  height: 40px;
}
</style>
