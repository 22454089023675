<template lang="html">
  <div class="modal fade" id="modal-refer-friend" tabindex="-1" role="dialog" aria-hidden="true" ref="modal">
    <div class="modal-dialog" role="document" style="max-width: 450px !important;">
      <div class="modal-content">
        <div :class="'klaviyo-form-' + klaviyoFormId"></div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api";
import axios from "axios";
import * as Toastr from "toastr";

export default {
  props: {
    isVisible: { type: Boolean, default: false },
    newPath: { type: String, required: true },
    createPath: { type: String, required: true },
    klaviyoFormId: { type: String },
  },
  data() {
    return {
      friendReferal: null,
      showInfo: false,
    };
  },
  watch: {
    isVisible(newValue, oldValue) {
      $(this.$refs.modal).modal("toggle");
      if (newValue) {
        this.newFriendReferal();
      }
    },
  },
  computed: {
    showForm() {
      return this.friendReferal && !this.showInfo;
    },
    hasMessage() {
      return this.friendReferal.message && this.friendReferal.message != "";
    },
  },
  methods: {
    newFriendReferal() {
      axios
        .get(this.newPath)
        .then((response) => {
          this.friendReferal = JsonApi.deserialize(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    create() {
      axios
        .post(this.createPath, {
          friend_referal: this.friendReferal,
        })
        .then((response) => {
          this.showInfo = true;
          this.friendReferal = JsonApi.deserialize(response.data);
        })
        .catch((error) => {
          $.each(error.response.data.errors, function (index, value) {
            Toastr.error(value);
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
