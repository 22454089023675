<template lang="html">
  <div class="">
    <div class="step-header">
      where do we ship the jewelry? (free shipping both ways)
    </div>
    <div class="row">
      <div class="col-sm-4 col-md-5">
        <img src="~images/app/subscriptions/step-3.webp" />
      </div>

      <div class="col-sm-8 col-md-7">
        <h3 class="fieldset-label">Add Shipping Information</h3>
        <address-form :address='address' @set-address='setAddress' v-if="edit" />
        <div v-else>
          <p>
            {{ address.street }}, {{ address.number }}
            <br>
            {{ address.city }}, {{ address.state }}, {{ address.zip }}</p>
          <a href="javascript:;" @click="edit=true" class="edit-btn">Edit</a>
        </div>

        <h3 class="fieldset-label">Review order</h3>
        <table>
          <tr>
            <td>Subtotal</td>
            <td>Free first month</td>
          </tr>
          <tr>
            <td>Insurance</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Shipping & handling</td>
            <td>Free</td>
          </tr>
          <tr>
            <td>Return via UPS</td>
            <td>Free</td>
          </tr>
          <tr class="total">
            <td>Order Total</td>
            <td>Free</td>
          </tr>
        </table>
        <div class="button-wrapper">
          <button type="button" class="btn btn-primary" v-on:click='nextStep'>Place Order</button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import AddressForm from "./address_fields";

export default {
  props: {
    address: { type: Object, required: true }
  },
  data() {
    return {
      edit: !this.address.persisted
    }
  },
  components: { AddressForm },
  methods: {
    setAddress(data) {
      this.edit = !data.persisted
      this.$emit('set-address', data)
    },
    nextStep() {
      this.$emit('next-step', '4')
    }
  }
}
</script>

<style lang="scss" scoped>

.fieldset-label {
  background: #EDEDED;
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

table {
  width: 100%;
  font-weight: bold;

  td{
    width: 50%;
    &:last-child {
      text-align: right;
    }

  }

  tr.total {
    padding-top: 10px;

    td {
      padding-top: 5px;
      border-top: 1px solid #000;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.button-wrapper {
  margin-top: 15px;

  .btn-primary {
    background: #000;
    color: #fff;
    border-color: #000;
  }

  text-align: right;
}

.edit-btn {
  text-decoration: underline;
  margin-bottom: 10px;
}
</style>
