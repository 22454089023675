<template>
  <div class="modal fade" id="modal-review" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class='modal-title'> Share your review</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">close x</span>
          </button>
        </div>
        <div class="modal-body">
          <div class='form-group mt-1'>
            <div>
            <label class='font-weight-bold'>Product condition upon arrival:</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_good"
                 value="good">
              <label class="form-check-label" for="condition_radio_good">Arrived in good contition</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_dirty"
                 value="dirty">
              <label class="form-check-label"
                for="condition_radio_dirty">Arrived in good contition, but dirty</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_scratches"
                 value="scratches">
              <label class="form-check-label"
                for="condition_radio_scratches">Small scratches (chiops, nicks)</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_damaged"
                 value="damaged">
              <label class="form-check-label"
                for="condition_radio_damaged">Product was damaged</label>
            </div>
          </div>

          <div class='form-group'>
            <label class="form-check-label font-weight-bold mr-1" for="inlineCheckbox1">Was the package damaged?</label>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.package_damaged'
                 class="form-check-input"
                 type="radio"
                 id="package_damaged_yes"
                 value="true">
              <label class="form-check-label" for="damaged_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='vendor_review.package_damaged'
                 class="form-check-input"
                 type="radio"
                 id="package_damaged_no"
                 value="false">
              <label class="form-check-label" for="package_damaged_no">No</label>
            </div>
          </div>

          <div class='form-group'>
            <label class='font-weight-bold'>Anything you would like to add about this product:</label>
              <input v-model='vendor_review.product_comment' class='form-control' />
          </div>

          <button @click='sendReview' type="submit" class="btn btn-outline-secondary btn-block">Send review</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: ['reviewPath', 'orderItemId'],
  data: function() {
    return {
      vendor_review: {
        condition: ''
      }
    }
  },
  methods: {
    clearForm() {
      this.vendor_review = {
        damaged: '',
        condition: '',
        product_comment: ''
      }
    },
    sendReview() {
      var that = this;

      $.ajax({
        method: 'POST',
        data: {
          vendor_review: that.vendor_review,
        },
        url: that.reviewPath,
        success: function(data) {
          that.$emit('review-created', data, that.orderItemId)
          Toastr.success('Vendor review is successfully added', 'Success');
          $('#modal-review').modal('hide')
          that.clearForm()
        },
        error: function(data) {
          Toastr.error(data.responseJSON.errors.join(', '), 'Error');
        }
      })
    }
  },
  watch: {
    reviewPath(){
      this.clearForm()
    }

  }

}
</script>
