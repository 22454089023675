<template>
  <span>
     <transition name="slide-fade" v-on:after-leave="afterLeave">
       <button class="btn btn-outline-secondary btn-animate btn-animate-side mx-1" type="button" @click="deleteItem" v-if="show">
         <span>
           <i class="icon fas fa-times"></i>
           {{ item.occasion.data.name }}
         </span>
       </button>
     </transition>
  </span>

</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    item: Object
  },
  data: function () {
    return {
      errors: {},
      show: true
    }
  },
  methods: {
    deleteItem: function() {
      var that = this;
      var items = this.$parent.items;

      $.ajax({
        method: 'DELETE',
        url: that.item.links.self,
        success: function(res) {
          that.show = false
          Toastr.success('Occasion removed!');
        },
        error: function(res) {
          that.errors = res.responseJSON.errors
          Toastr.error(that.errors.join(), 'Error removing occasion.');
        }
      })
    },
    afterLeave: function() {
      var items = this.$parent.items;
      items.splice(items.indexOf(this.item), 1)
    }
  }
}
</script>

<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
