import Vue from "vue/dist/vue.esm";
import * as Toastr from 'toastr';
import JsonApi from "../../vendor/json-api"

import UnitItem from "../../components/units/item";

export default class AdminProductsUnits {
  index() {
    var app = new Vue({
      el: '#product-units-app',
      propsData: {
        newpath: String
      },
      components: { UnitItem },
      data: {
        units: JsonApi.deserialize(gon.payload).data,
        newPath: 'String'
      },
      mounted: function () {
        this.newPath = $(this.$el).data('new-path');
      },
      methods: {
        addItem: function(value, $event) {
          var that = this;

          $.ajax({
            method: 'GET',
            url: that.newPath + '?variant_id=' + value,
            success: function(data) {
              that.units.push(JsonApi.deserialize(data));
            },
            error: function(data) {
              Toastr.error(data.responseJSON.errors, 'Error adding unit');
            }
          });
        }
      }
    });
  }
}
