<template lang="html">
  <div >
    <div class="form-group">
      <label :for="id_for('street')">Street & Number</label>
      <input type="text" class="form-control" :id="id_for('street')" placeholder="Street & Number" v-model="address.street" v-bind:class="{ 'is-invalid': errors.address.street }" v-on:keyup.enter="save">
      <div v-if="errors.address.street" class="invalid-feedback">{{ errors.address.street.join(', ') }}</div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label :for="id_for('number')">Apt. Number</label>
          <input type="text" class="form-control" :id="id_for('number')" placeholder="Apt. Number" v-model="address.number" v-bind:class="{ 'is-invalid': errors.address.number }" v-on:keyup.enter="save">
          <div v-if="errors.address.number" class="invalid-feedback">{{ errors.address.number.join(', ') }}</div>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label :for="id_for('city')">City</label>
          <input type="text" class="form-control" :id="id_for('city')" placeholder="City" v-model="address.city" v-bind:class="{ 'is-invalid': errors.address.city }" v-on:keyup.enter="save">
          <div v-if="errors.address.city" class="invalid-feedback">{{ errors.address.city.join(', ') }}</div>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label :for="id_for('state')">State</label>
          <select v-model="address.state"  class="mb-3 custom-select" v-bind:class="{ 'is-invalid': errors.address.state }">
            <option :value='state.abbreviation' v-for='state in states'>{{ state.name }}</option>
          </select>
          <div v-if="errors.address.state" class="invalid-feedback">{{ errors.address.state.join(', ') }}</div>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label :for="id_for('zip')">Zip Code</label>
          <input type="text" class="form-control" :id="id_for('zip')" placeholder="Zip Code" v-model="address.zip" v-bind:class="{ 'is-invalid': errors.address.zip }" v-on:keyup.enter="save">
          <div v-if="errors.address.zip" class="invalid-feedback">{{ errors.address.zip.join(', ') }}</div>
        </div>
      </div>
    </div>

    <div class="button-wrapper">
      <button type="button" class="btn btn-primary" v-on:click='save'>Save address</button>

    </div>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api"
import * as Toastr from 'toastr';
var UsaStates = require('usa-states').UsaStates;

export default {
  props: {
    address: {type: Object, required: true},
  },
  data: function() {
    return {
      errors: {
        address: {}
      }
    }
  },
  computed: {
    states() {
      var usStates = new UsaStates();
      return usStates.states;
    },
    saveMethod: function() {
      return this.address.persisted ? 'PUT' : 'POST';
    },
  },
  methods: {
    id_for: function(key) {
      return 'address_' + this.address.id + '_' + key
    },
    save() {
      var that = this;

      $.ajax({
        method: that.saveMethod,
        data: {
          address: that.address
        },
        url: that.address.links.self,
        success: function(data) {
          that.$emit('set-address', JsonApi.deserialize(data))

          that.errors.address = {}
          Toastr.success('Address saved successful!');

        },
        error: function(data) {
          that.errors.address = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving Address');
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-primary {
  background: #000;
  color: #fff;
  border-color: #000;
}

.button-wrapper {
  text-align: right;
}
</style>
