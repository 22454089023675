import Vue from "vue/dist/vue.esm";
import JsonApi from "../../../vendor/json-api";
import Order from '../../components/account/orders/order.vue';
import ReviewModal from '../../components/account/orders/review_modal.vue';
import ShowReviewModal from "../../components/account/orders/show_review_modal"

export default class UsersOrders {
  index() {
    let app = new Vue({
      el: '#app-orders',
      components: { Order, ReviewModal, ShowReviewModal },
      data: {
        orders: JsonApi.deserialize(gon.orders).data,
        reviewPath: null,
        showUserReview: {},
        currentOrderItemId: null
      },
      methods: {
        showReviewModal(review) {
          this.showUserReview = review
        },
        reviewModal(path, orderItemId) {
          this.reviewPath = path
          this.currentOrderItemId = orderItemId
        },
        reviewCreated(userReview) {
          let that = this
          let deserializedUserReview = JsonApi.deserialize(userReview)

          that.orders.forEach(function(order, order_index){
            order.order_items.data.forEach(function(order_item, order_item_index){
              if(that.currentOrderItemId == order_item.id) {
                that.orders[order_index].order_items.data[order_item_index]['user_review']['data'] = deserializedUserReview
              }
            });
          });
        }
      }
    });

    // $('#show-modal-review').on('hidden.bs.modal', function (e) {
    //   this.__vue__.$parent.showUserReview = {}
    // })
  }

  show() {
    this.index();
  }
}
