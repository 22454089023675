<template>
  <div class="product-thumb">
    <div class="image-container">
      <a :href="`/rent-jewelry/` + product.slug">
        <div class="image" :style="`background-image: url('${product.image_path}')`"></div>
      </a>
      <div class="details">
        <a class="title" :href="`/rent-jewelry/` + product.slug">{{
          product.title
        }}</a>
        <div
          v-if="isUser"
          class="favorite-action"
          :id="'product-thub-favorite-rbtd'"
        >
          <a
            data-remote="true"
            rel="nofollow"
            :data-method="product.favourite ? `post` : `delete`"
            :href="product.fav_href"
            @click="$emit('favoriteHandler', product)"
          >
            <HeartIcon v-bind:isFav="product.favourite" />
          </a>
        </div>
        <div class="sub">
          <div>
            <span>Rent for: $</span>
            <span>{{ product.rental_price.cents }}</span>
            <span>&nbsp;/ Per Day</span>
          </div>

          <span>Market Price:$</span>
          <span id="market_price">{{
            product.market_price.cents
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeartIcon from "../../../../images/app/products/heartSvg.vue";

export default {
  name: 'ProductItem',
  components: {
    HeartIcon
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.product-thumb {
  margin-bottom: 10px;
  flex: 0 1;
  padding: 0px;
  min-width: 25%;

  .image-container {
    margin-bottom: 15px;
    a {
      display: flex;
      justify-content: center;

      .image {
        height: 220px;
        width: 100%;
        padding: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-color: #fff;
        margin-bottom: 10px;

        @media(max-width: 775px) {
          background-size: contain;
        }

        @media(max-width: 450px) {
          padding: 0;
        }
      }
    }
  }

  .details {
    position: relative;
    color: black;

    .favorite-action {
      position: absolute;
      top: -23px;
      right: 4px;
      @include font-size(22px);
      line-height: 1em;
      @include transition(all 0.2s ease);

      svg {
        fill: white;
        stroke-width: 1.5;
        stroke: black;
        height: 16px;

        path {
          transition: all 0.5s;
        }

        @media (min-width: 1150px) {
          &:hover {
            path {
              fill: #ffc000 !important;
            }
          }
        }
      }
    }
    a.title {
      font-family: "Utile Medium";
      font-size: 14px;
      margin-bottom: 5px;
      line-height: 17px;
      display: inline-block;
      color: black;

      @media(max-width: 450px) {
        font-size: 12px;
      }
    }
    .sub {
      font-size: 0;
      line-height: 12px;
      span,
      p {
        font-family: "Utile Book";
        
        @media(min-width: 450px) {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 775px) {
  .product-thumb .details {
    padding-right: 50px;
    text-align: center;
  }
}

@media (max-width: 570px) {
  .product-thumb {
    min-width: auto;
    flex-basis: 50%;
    padding: 0px 4%;

    .details {
      padding: 4px;

      .favorite-action {
        @include font-size(12px);
        top: -17px;
        right: -10px;
      }
    }

    a.title {
      font-size: 12px;
    }
    .sub {
      span,
      p {
        font-size: 11px;
      }
    }

    .image-container a .image {
      height: 180px;
    }
  }
}

@media (max-width: 450px) {
  .product-thumb .image-container a .image {
    height: 140px;
  }
}
</style>