<template>
  <a href="/accounts/auth/facebook" data-method="post" class="btn btn-block fb-btn">
    <div v-if="withIcon" class="fb-ico"></div>
    {{ buttonText }}
  </a>
</template>

<script>
  export default {
    props: {
      withIcon: {type: Boolean, required: true},
      modalName: {type: String, required: true}
    },
    data(){
      return{
        buttonText: this.$_isInRegisterModal() ? "SIGN UP WITH FACEBOOK" : "Sign in with Facebook"
      }
    },
    methods: {
      $_isInRegisterModal(){
        return this.modalName.localeCompare("register") === 0
      }
    }
  }
</script>
