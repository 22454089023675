import Vue from "vue/dist/vue.esm";
import * as Toastr from 'toastr';
import JsonApi from "../../vendor/json-api"
// import JSONAPIDeserializer from "jsonapi-serializer";

import VariantItem from "../../components/variants/item";

export default class AdminProductsVariants {
  index() {
    var app = new Vue({
      el: '#product-variants-app',
      propsData: {
        newpath: String
      },
      components: { VariantItem },
      data: {
        variants: JsonApi.deserialize(gon.payload).data,
        newPath: 'String'
      },
      mounted: function () {
        this.newPath = $(this.$el).data('new-path');
      },
      methods: {
        addVariant: function() {
          var that = this;

          $.ajax({
            method: 'GET',
            url: that.newPath,
            success: function(data) {
              that.variants.push(JsonApi.deserialize(data));
            },
            error: function(data) {
              Toastr.error(data.responseJSON.errors, 'Error adding variant');
            }
          });

          // $.get(this.newPath, function(data) {
          //   that.variants.push(JsonApi.deserialize(data));
          // });
        }
      }
    });

    // JSONAPIDeserializer needs update on links
    // var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
    // new JSONAPIDeserializer({keyForAttribute: 'underscore_case'}).deserialize(gon.payload, function (err, data) {
    //   app.variants = data
    // });
  }
}
