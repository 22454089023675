<template>
  <tr class="product-row">
    <td><img :src="item.attributes.thumb"></td>
    <td>{{item.attributes.title}}</td>
    <td>
      <strong>{{item.attributes.vendor_name}}</strong>
      <div>{{item.attributes.company_name}}</div>
    </td>
    <td>
      <strong>{{item.attributes.rental_format}}</strong>
      daily
      <div>{{item.attributes.rental_price}}</div>
      <span class="badge badge-outline badge-primary">{{item.attributes.purchase_option}}</span>
    </td>
    <td>
      <a class="btn btn-primary btn-outline" :href="'/admin/products/' + item.attributes.slug">Edit</a>
    </td>
  </tr>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: {
    item: {type: Object, required: true}
  },
  data: function () {
    return {
      show: true
    }
  }
}
</script>
