<template>
  <div>
    <a @click="togglePersonaModal" id="persona">
      Click here to scan
    </a>
    <div v-if="modalVisible" id="skip-persona-modal">
      <div class="container">
        <h2>WARNING!</h2>
        <p>You have not completed the Drivers License / State ID Scan.</p>
        <div>
          <a @click="scanNow">SCAN NOW</a>
          <a @click="scanLater">I'LL DO IT LATER</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    personaEnvironment: { type: String },
    personaTemplateId: { type: String, required: true },
    personaEnvironmentId: { type: String, required: true },
    modalVisible: { type: Boolean, required: true },
  },
  data() {
    return { 
      personaClient: new Persona.Client({
        templateId: this.personaTemplateId,
        environmentId: this.personaEnvironmentId,
        environment: this.personaEnvironment,
        onComplete: ({ inquiryId, status, fields }) => {
          console.log(`Completed inquiry ${inquiryId} with status ${status}, this.personaVerified = true`);
          this.$emit("verificationCompleted");
        }
      }),
    }
  },
  methods: {
    togglePersonaModal: function() {
      this.personaClient.open();
    },
    scanNow: function() {
      this.$emit("scanNow");
      setTimeout(this.togglePersonaModal, 200);
    },
    scanLater: function() {
      this.modalVisible = false;
      this.$emit("scanLater");
    }
  }
}
</script>
