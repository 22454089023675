import Vue from "vue/dist/vue.esm";
import * as Toastr from 'toastr';

import CouponCart from "../../components/checkout/coupon_cart";

export default class UsersSubscriptions {
  index() {
    var button = document.querySelector('#store-card');

    braintree.dropin.create({
      authorization: gon.client_token,
      container: '#dropin-container',
    }, function (createErr, instance) {
      button.addEventListener('click', function () {
        if (button.disabled) {
          return;
        }
        button.disabled = true;
        button.textContent = 'Loading ...'

        instance.requestPaymentMethod(function (error, payload) {
          if (error) {
            Toastr.error('There was error with your payment. ' + error.message + ' Please try again.', 'Payment error.');
            button.disabled = false;
            button.textContent = 'Go to checkout'
          } else {
            Toastr.success('Credit card stored, please continue to checkout.', 'Card verified.');
            $('#payment_method_nonce').val(payload.nonce);
            $('#payment_method_nonce').parents('form').submit();
          }
        });
      });
    });
  }
}
