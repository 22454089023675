<template>
  <div class="modal fade" id="modal-review" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class='modal-title'> Share your review</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">close x</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div>
              <label class='font-weight-bold'>Rating</label>
            </div>
            <star-rating v-model="user_review.rating" :star-size="20" :inline="true" :show-rating="false"></star-rating>
          </div>
          <div class='form-group mt-1'>
            <div>
            <label class='font-weight-bold'>Rented this for:</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_0"
                 value="date">
              <label class="form-check-label" for="occasion_radio_0">Date</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_1"
                 value="every_day">
              <label class="form-check-label" for="occasion_radio_1">Every Day</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_2"
                 value="formal_affair">
              <label class="form-check-label" for="occasion_radio_2">Formal Affair</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_3"
                 value="party">
              <label class="form-check-label" for="occasion_radio_3">Party</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"

                 id="occasion_radio_4"
                 value="vacation">
              <label class="form-check-label" for="occasion_radio_4">Vacation</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_5"
                 value="wedding">
              <label class="form-check-label" for="occasion_radio_5">Wedding</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.occasion'
                 class="form-check-input"
                 type="radio"
                 id="occasion_radio_6"
                 value="other">
              <label class="form-check-label" for="occasion_radio_6">Other</label>
            </div>
          </div>
          <div class='form-group mt-1'>
            <div>
            <label class='font-weight-bold'>Product condition upon arrival:</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_0"
                 value="good">
              <label class="form-check-label" for="condition_radio_0">Arrived in good condition</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_1"
                 value="package_damaged">
              <label class="form-check-label" for="condition_radio_1">The package was damaged</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.condition'
                 class="form-check-input"
                 type="radio"
                 id="condition_radio_2"
                 value="product_damaged">
              <label class="form-check-label" for="condition_radio_2">The product was damaged</label>
            </div>
          </div>

          <div class='form-group mt-1'>
            <div>
            <label class='font-weight-bold'>Was this product</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.satisfaction'
                 class="form-check-input"
                 type="radio"
                 id="satisfaction_radio_0"
                 value="expected">
              <label class="form-check-label" for="satisfaction_radio_0">What you expected</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.satisfaction'
                 class="form-check-input"
                 type="radio"
                 id="satisfaction_radio_1"
                 value="better">
              <label class="form-check-label" for="satisfaction_radio_1">Better than what you expected</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.satisfaction'
                 class="form-check-input"
                 type="radio"
                 id="satisfaction_radio_2"
                 value="worse">
              <label class="form-check-label" for="satisfaction_radio_2">Worse than what you expected</label>
            </div>
          </div>

          <hr>
          <div class='form-group'>
            <label class="form-check-label font-weight-bold mr-1" for="inlineCheckbox1">Did the product arrive on time?</label>
            <div class="form-check form-check-inline">
              <input v-model='user_review.on_time'
                 class="form-check-input"
                 type="radio"
                 id="on_time_yes"
                 value="true">
              <label class="form-check-label" for="on_time_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.on_time'
                 class="form-check-input"
                 type="radio"
                 id="on_time_no"
                 value="false">
              <label class="form-check-label" for="on_time_no">No</label>
            </div>
          </div>
          <div class='form-group'>
            <label class="form-check-label font-weight-bold mr-1" for="inlineCheckbox1">Would you rent from this vendor again?</label>
            <div class="form-check form-check-inline">
              <input v-model='user_review.rent_again'
                 class="form-check-input"
                 type="radio"
                 id="rent_again_yes"
                 value="true">
              <label class="form-check-label" for="rent_again_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.rent_again'
                 class="form-check-input"
                 type="radio"
                 id="rent_again_no"
                 value="false">
              <label class="form-check-label" for="rent_again_no">No</label>
            </div>

          </div>
          <div class='form-group'>
            <label class="form-check-label font-weight-bold mr-1" for="inlineCheckbox1">Would you recommend this jewelry to a friend?</label>
            <div class="form-check form-check-inline">
              <input v-model='user_review.recommend'
                 class="form-check-input"
                 type="radio"
                 id="recommend_yes"
                 value="true">
              <label class="form-check-label" for="recommend_yes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model='user_review.recommend'
                 class="form-check-input"
                 type="radio"
                 id="recommend_no"
                 value="false">
              <label class="form-check-label" for="recommend_no">No</label>
            </div>


          </div>
          <div class='form-group'>
            <label class='font-weight-bold'>Anything you would like to add about this product:</label>
            <input v-model='user_review.product_comment' class='form-control' />
          </div>

          <div class='form-group'>
            <label class='font-weight-bold'>Anything you would like to add about this vendor:</label>
            <input v-model='user_review.vendor_comment' class='form-control' />
          </div>

          <div class='form-group'>
            <label class='font-weight-bold'>Upload photo of the item</label>
            <input type="file" @change="handleFileUpload" class="form-control d-block">
          </div>

          <div class="my-4">
            <img :src="imagePreview" v-show="showPreview" class="img-thumbnail image-preview"/>
          </div>

          <button @click='sendReview' type="submit" class="btn btn-outline-secondary btn-block">Send review</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import objectToFormData from 'object-to-formdata';
import StarRating from 'vue-star-rating';

// import Api from '../../../../vendor/api';
import * as Toastr from 'toastr';

export default {
  props: ['reviewPath', 'orderItemId'],
  components: {
    StarRating: StarRating
  },
  data: function() {
    return {
      user_review: {
        rating: 0,
        file: null
      },
      imagePreview: '',
      showPreview: false
    }
  },
  methods: {
    clearForm() {
      this.user_review = {
        rating: 0
      }
    },
    handleFileUpload(event){


      /*
        Set the local file variable to what the user has selected.
      */
      this.user_review.file = event.target.files[0];

      /*
        Initialize a File Reader object
      */
      let reader  = new FileReader();

      /*
        Add an event listener to the reader that when the file
        has been loaded, we flag the show preview as true and set the
        image to be what was read from the reader.
      */
      reader.addEventListener("load", function () {
        this.showPreview = true;
        this.imagePreview = reader.result;
      }.bind(this), false);

      /*
        Check to see if the file is not empty.
      */
      if( this.user_review.file ){
        /*
          Ensure the file is an image file.
        */
        if ( /\.(jpe?g|png|gif)$/i.test( this.user_review.file.name ) ) {
          /*
            Fire the readAsDataURL method which will read the file in and
            upon completion fire a 'load' event which we will listen to and
            display the image in the preview.
          */
          reader.readAsDataURL( this.user_review.file );
        }
      }
    },
    sendReview() {
      var that = this;
      var formData = objectToFormData({user_review: that.user_review});

      axios.post(that.reviewPath + '.json', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        that.$emit('review-created', response.data)
        Toastr.success('User review is successfully added', 'Success');
        $('#modal-review').modal('hide')
      }).catch(error => {
        Toastr.error(error.response.data.errors.join(', '), 'Error');
      });
    }
  },
  watch: {
    reviewPath(){
      this.clearForm()
    }

  }

}
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 100%;
}

</style>
