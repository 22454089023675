<template>
<div class="order-wrapper">
  <div class="order-header ">
    <div class="row">
      <div class="col-6">
        Order Number:
        <a :href="order.links.self">{{order.id_formatted}}</a>
        <br>
        <span>
          {{createdAt}}
        </span>
      </div>

      <div class="col-6 text-right">
        <a :href="order.links.self" class="btn btn-outline-primary btn-sm">Order Details</a>
      </div>
    </div>


  </div>
  <div class="order-body">
    <div class="row">
      <div class="col-md-3">
        <div class="address">
          <div class="mb-3">
            <strong>Shipping Address</strong>
            <div class="sub text-black-50">
              {{ order.shipping_formatted }}
            </div>
          </div>
        </div>
        <div class="address">
          <div class="mb-3">
            <strong>Billing Address</strong>
            <div class="sub text-black-50">
              {{ order.billing_formatted }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mb-3">
          <strong>Expected date to arrive</strong>
          <div class="sub text-black-50">
            {{ order.rent_at }}
          </div>
        </div>
        <div class="mb-3">
          <strong>Date to be sent back</strong>
          <div class="sub text-black-50">
            {{ order.return_at }}
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <strong>Rental price</strong>
          <div class="sub text-black-50">
            {{ order.rental_price_formatted }}
          </div>
        </div>
        <div class="mb-3">
          <strong>Market price</strong>
          <div class="sub text-black-50">
            {{ order.market_price_formatted }}
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center mb-3">
        <div class="mb-3">
          <strong>Status</strong>
          <div class="sub text-black-50 text-uppercase">
            {{ order.status_humanized }}
          </div>
        </div>
        <button class="btn btn-primary">View Invoice</button>
      </div>
    </div>
    <hr class="mt-0"/>

    <order-item
      v-for='order_item in order.order_items.data'
      :order_item='order_item'
      :key='order_item.id'
      @review-modal='reviewModal'
      @show-review-modal='showReviewModal'
      @order-cancelled='orderCancelled'></order-item>
  </div>
</div>
</template>

<script>
import OrderItem from './order_item'
import * as moment from 'moment';

export default {
  components: {
    OrderItem
  },
  props: {
    order: {type: Object, required: true}
  },
  computed: {
    createdAt() {
      return moment(this.order.created_at).format('Do MMM YYYY H:m:s')
    },
  },
  methods: {
    reviewModal(path, orderItemId) {
      this.$emit('review-modal', path, orderItemId)
    },
    showReviewModal(review) {
      this.$emit('show-review-modal', review)
    },
    orderCancelled() {
      this.order.status_humanized = "Cancelled";
    }
  }
}
</script>
