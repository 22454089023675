<template>
  <div
    class="modal fade"
    id="modal-register"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <svg
            class="close"
            data-dismiss="modal"
            height="50"
            stroke-linecap="round"
            stroke-width="3"
            stroke="black"
            viewBox="0 0 50 50"
            width="50"
          >
            <line x1="0" x2="50" y1="0" y2="50"></line>
            <line x1="50" x2="0" y1="0" y2="50"></line>
          </svg>
        </div>
        <div class="logo-container text-center mb-4">
          <div class="logo"></div>
        </div>
        <div class="my-3 text-center">
          <h5 class="welcome-title">Welcome to Trejours!</h5>
        </div>
        <div class="signup-method-wrapper" v-if="status !== 'complete'">
          <FacebookButton
              :modal-name="'register'"
              :with-icon=true
          />
          <a
            href="/accounts/auth/google_oauth2"
            class="btn btn-block google-btn"
            data-method="post"
            rel="nofollow"
          >
            <div class="google-ico"></div>
            SIGN UP WITH GOOGLE
          </a>
        </div>
        <div class="modal-body">
          <div class="text-center p-4" v-if="status === 'complete'">
            <h5 class="register-success">Success!</h5>
            <p>
              Your account has been registered
            </p>

            <p>
              You will receive <br /><span class="underline">a confirmation email.</span>
            </p>

            <p>
              Please click on the link in the email<br /> to activate your account.
            </p>
          </div>

          <div v-if="status === 'pending'">
            <div class="form-group">
              <label for="register-first-name" class="form-title"
                >FIRST NAME</label
              >
              <input
                type="text"
                class="form-control"
                id="register-first-name"
                placeholder=""
                v-model="first_name"
                v-bind:class="{ 'is-invalid': errors.first_name }"
                @keyup.enter="register"
              />
              <div v-if="errors.first_name" class="invalid-feedback">
                {{ errors.first_name.join(", ") }}
              </div>
            </div>

            <div class="form-group">
              <label for="register-last-name" class="form-title"
                >LAST NAME</label
              >
              <input
                type="text"
                class="form-control"
                id="register-last-name"
                placeholder=""
                v-model="last_name"
                v-bind:class="{ 'is-invalid': errors.last_name }"
                @keyup.enter="register"
              />
              <div v-if="errors.last_name" class="invalid-feedback">
                {{ errors.last_name.join(", ") }}
              </div>
            </div>

            <div class="form-group">
              <label for="register-email" class="form-title">EMAIL</label>
              <input
                type="email"
                class="form-control"
                id="register-email"
                placeholder=""
                v-model="email"
                v-bind:class="{ 'is-invalid': errors.email }"
                @keyup.enter="register"
              />
              <div v-if="errors.email" class="invalid-feedback">
                {{ errors.email.join(", ") }}
              </div>
            </div>

            <div class="form-group">
              <label for="register-password" class="form-title">PASSWORD</label>
              <input
                type="password"
                class="form-control"
                id="register-password"
                placeholder=""
                v-model="password"
                v-bind:class="{ 'is-invalid': errors.password }"
                @keyup.enter="register"
              />
              <div v-if="errors.password" class="invalid-feedback">
                {{ errors.password.join(", ") }}
              </div>
            </div>
          </div>
        </div>
        <div class="recaptcha-wrapper">
          <vue-recaptcha
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="sitekey"
          >
          </vue-recaptcha>
        </div>
        <button
          type="submit"
          class="submit-btn"
          id="create-account"
          @click="register"
          v-if="status !== 'complete'"
        >
          {{ registerButtonText }}
        </button>
        <button type="submit" class="login-acc-button" @click="showLoginModal" v-if="status !== 'complete'">
          Already have an account? Login here.
        </button>

        <button class="submit-btn" data-dismiss="modal" v-if="status === 'complete'">
          OK
        </button>
        <p class="spam-notice text-center p4" v-if="status === 'complete'">
          If you do not see an email from Trejours in your inbox,<br/> please
          check your spam folder.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from "toastr";
import VueRecaptcha from "vue-recaptcha";
import FacebookButton from './facebook_button'
export default {
  props: {
    path: { type: String, required: true },
    vendor_sing_up_path: { type: String, required: true },
    recaptcha_path: { type: String, required: true },
    sitekey: { type: String, required: true },
  },
  components: {
    VueRecaptcha: VueRecaptcha,
    FacebookButton: FacebookButton,
  },
  data: function () {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      errors: {},
      status: "pending",
      recaptchaChallange: null,
      recaptchaSuccessfull: false,
      isLoading: false,
    };
  },
  computed: {
    registerButtonText: function () {
      return this.isLoading ? "Loading..." : "Create Account";
    },
  },
  methods: {
    register() {
      if (!this.recaptchaSuccessfull) {
        Toastr.error(
          "Please complete anti-spam verification",
          "Registration error"
        );
        return;
      }

      if (this.isLoading) {
        Toastr.error("Please Wait.", "Loading");
        return;
      }

      var that = this;

      this.isLoading = true;
      $.ajax({
        method: "POST",
        data: {
          account: {
            first_name: that.first_name,
            last_name: that.last_name,
            email: that.email,
            password: that.password,
          },
          redirect_url: window.location.href,
          "g-recaptcha-response": that.recaptchaChallange,
        },
        url: that.path,
        success: function (data) {
          that.isLoading = false;
          that.errors = {};
          that.status = "complete";
          location.href = data.url;
        },
        error: function (data) {
          that.isLoading = false;
          that.recaptchaChallange = null;
          that.recaptchaSuccessfull = false;
          that.resetRecaptcha();
          that.errors = data.responseJSON.errors;
          Toastr.error(
            "Please correct the errors in the registration form",
            "Registration error"
          );
        },
      });
    },
    showLoginModal() {
      $("#modal-register").modal("hide");
      $("#modal-login").modal("show");
    },
    onVerify: function (response) {
      this.recaptchaChallange = response;
      this.recaptchaSuccessfull = true;
    },
    onExpired: function () {
      Toastr.error(
        "Anti-spam verification expired. Please verify again.",
        "Registration error"
      );
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style type="scss" scoped>
#modal-register {
  overflow: auto !important;
}
.recaptcha-wrapper {
  margin: 10px 0;
}

.close {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 55px;
  height: 55px;
  cursor: pointer;
}

.modal-body {
  padding: 0 4em;
}
@media (max-width: 835px) {
  .modal-body {
    padding: 0 1em;
  }
}
.modal-content {
  border: 6px solid #212121;
  max-width: 555px;
  font: normal normal normal 18px/20px Utile;
}

.logo-container {
  height: 158px;
  background: rgb(203, 155, 78, 0.18);
  width: 95%;
  margin-left: 5%;
}

.logo {
  background: url("../../../../images/app/register/logo.svg");
  background-position: center;
  background-size: cover;
  width: 170px;
  height: 170px;
  margin: 0 auto;
}

.welcome-title {
  font: normal normal normal 22px/25px Utile Display Book;
  letter-spacing: 0px;
  color: #000000;
  border-bottom: solid 1px black;
  width: fit-content;
  margin: 0 auto 2em;
  padding-bottom: 0.2em;
}

.signup-method-wrapper {
  margin: 0 auto 3em;
}

::v-deep .fb-btn {
  width: 230px;
  height: 34px;
  background: #817f7f 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal normal 15px/18px Utile;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
}

::v-deep .fb-ico {
  width: 24px;
  height: 22px;
  background: url("../../../../images/app/register/fb-icon-white.svg");
  background-position: center;
  background-size: cover;
  margin-right: 0.5em;
}

.google-btn {
  width: 230px;
  height: 34px;
  background: #817f7f 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal normal 15px/18px Utile;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
}

.google-ico {
  width: 24px;
  height: 22px;
  background: url("../../../../images/app/register/google-icon-white.svg");
  background-position: center;
  background-size: cover;
  margin-right: 0.5em;
}

.form-title {
  font: normal normal normal 12px/14px Utile;
  letter-spacing: 0px;
  color: #000000;
}

.recaptcha-wrapper {
  margin: 3em auto 0;
}

.submit-btn {
  width: 218px;
  height: 44px;
  background: #343333 0% 0% no-repeat padding-box;
  margin: 3em auto 1em;
  border: none;
  font: normal normal normal 15px/18px Utile;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  padding-bottom: 0.5em;
}

.submit-btn:hover {
  background: #181818;
}

.submit-btn:focus {
  background: #181818;
}

.login-acc-button {
  background: none;
  border: none;
  border-bottom: solid 1px #7e7d7d;
  padding-bottom: 4px;
  font-family: Utile;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  margin: 0 auto 1em;
  width: fit-content;
}
.login-acc-button:hover {
  opacity: 0.7;
}

.underline {
  text-decoration: underline;
}

.register-success {
  font: normal normal normal 22px/25px Utile Display Book;
}

.spam-notice {
  font: normal normal normal 14px/20px Utile Display Book;
}
</style>
