<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="vendor-store-name">Store Name</label>
          <input type="text" class="form-control" id="vendor-store-name" placeholder="Store name" v-model="store.name" v-bind:class="{ 'is-invalid': errors.store.name }" v-on:keyup.enter="update">
          <div v-if="errors.store.name" class="invalid-feedback">{{ errors.vendor.store.join(', ') }}</div>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="vendor-store-phone">Store Phone</label>
          <input type="text" class="form-control" id="vendor-store-phone" placeholder="Store Phone" v-model="store.phone" v-bind:class="{ 'is-invalid': errors.store.phone }" v-on:keyup.enter="update">
          <div v-if="errors.store.phone" class="invalid-feedback">{{ errors.store.phone.join(', ') }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="vendor-store-street">Store Street Address</label>
          <input type="text" class="form-control" id="vendor-store-street" placeholder="Store Street Address" v-model="store.street" v-bind:class="{ 'is-invalid': errors.store.street }" v-on:keyup.enter="update">
          <div v-if="errors.store.street" class="invalid-feedback">{{ errors.store.street.join(', ') }}</div>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="vendor-store-number">Office/Store Number</label>
          <input type="text" class="form-control" id="vendor-store-number" placeholder="Office/Store Number" v-model="store.number" v-bind:class="{ 'is-invalid': errors.store.number }" v-on:keyup.enter="update">
          <div v-if="errors.store.number" class="invalid-feedback">{{ errors.store.number.join(', ') }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="vendor-store-city">Store City</label>
          <input type="text" class="form-control" id="vendor-store-city" placeholder="Store City" v-model="store.city" v-bind:class="{ 'is-invalid': errors.store.city }" v-on:keyup.enter="update">
          <div v-if="errors.store.city" class="invalid-feedback">{{ errors.store.city.join(', ') }}</div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label for="vendor-store-zip">Store ZIP code</label>
          <input type="text" class="form-control" id="vendor-store-zip" placeholder="Store ZIP code" v-model="store.zip" v-bind:class="{ 'is-invalid': errors.store.zip }" v-on:keyup.enter="update">
          <div v-if="errors.store.zip" class="invalid-feedback">{{ errors.store.zip.join(', ') }}</div>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="vendor-store-state">Store State</label>
          <b-form-select id="vendor-store-state" v-model="store.state" :options="states" value-field="abbreviation" text-field="name" class="mb-3" v-bind:class="{ 'is-invalid': errors.store.state }" />
          <div v-if="errors.store.state" class="invalid-feedback">{{ errors.store.state.join(', ') }}</div>
        </div>
      </div>


    </div>

    <button type="button" class="btn btn-primary" v-on:click='update'>Save changes</button>

  </div>
</template>

<script>
import * as Toastr from 'toastr';
var UsaStates = require('usa-states').UsaStates;

export default {
  props: {
    account: {type: Object, required: true},
    store: {type: Object, required: true},
    path: {type: String, required: true}
  },
  data: function() {
    return {
      errors: {
        store: {}
      }
    }
  },
  computed: {
    states() {
      var usStates = new UsaStates();
      return usStates.states;
      
    }
  },
  methods: {
    update() {
      var that = this;

      $.ajax({
        method: 'PUT',
        data: {
          store: that.store,
        },
        url: that.path,
        success: function(data) {
          that.errors.store = {}
          Toastr.success('Store updated successful!');
        },
        error: function(data) {
          that.errors.store = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving Store');
        }
      });
    }
  }
}
</script>
