<template lang="html">
  <a class="list-group-item list-group-item-action" href="javascript:;" v-on:click="setItem">
    <span v-if="!item.persisted" class="mr-1">Create collection </span>
    {{item.name}}
  </a>
</template>

<script>
import JsonApi from "../../../../vendor/json-api"
import * as Toastr from 'toastr';

export default {
  props: {
    item: Object,
    addpath: String,
    createpath: String
  },
  methods: {
    setItem() {
      if (this.item.persisted) {
        this.addItem(this.item.id)
      } else {
        this.createItem()
      }
    },
    addItem(id) {
      var that = this;

      $.ajax({
        method: 'POST',
        data: JsonApi.serialize({
          collection_products: {
            collection_id: id
          }
        }),
        url: that.addpath,
        success: function(data) {
          // add category to the main list
          that.$parent.$parent.items.push(JsonApi.deserialize(data))
          // clear search results
          that.$parent.items = []
          // clear input
          $(that.$parent.$refs.searchOccasions).val('')
          Toastr.success('Collection added!');
        },
        error: function(data) {
          Toastr.error(data.responseJSON.errors.join(), 'Error adding collection');
        }
      })
    },
    createItem() {
      var that = this;
      $.ajax({
        method: 'POST',
        data: JsonApi.serialize({
          collection: {
            name: that.item.name
          }
        }),
        url: that.createpath,
        success: function(data) {
          that.addItem(JsonApi.deserialize(data).id)
        },
        error: function(data) {
          Toastr.error(data.responseJSON.errors.join(), 'Error adding collection');
        }
      });
    }
  }
}
</script>
