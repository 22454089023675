<template>
	<div class="collapsed" id="checkout-verification">
		<div v-if="isEdit">
			<div>
				<p id="disclaimer">
					For safety and security reasons, we need to collect information
					confirming your identity. For more info, please check out our Privacy
					Policy.
				</p>

				<div v-if="!user.phone_verified" id="mobile-phone">
					<div class="header">
						Mobile Phone
					</div>
					<p>
						MOBILE PHONE VERIFICATION
					</p>
					<a id="send-sms" @click="verifyPhoneRequest">{{ sendText }}</a>
					<label for="sms-code">
						TYPE VERIFICATION CODE HERE
					</label>
					<div id="sms-code-wrapper">
						<input v-model="code" type="text" name="sms-code" id="sms-code" />
						<div id="sms-code-sent" v-if="isSent">* We have sent a code to your phone. Please check your messages for this code.</div>
					</div>
				</div>

				<div id="date-of-birth">
					<div class="header">
						Date of Birth
					</div>
					<div class="form-item sms-item" :class="birthDateErrorHandler">
						<label class="sms-label" for="birth">DATE OF BIRTH</label>
						<input @click="birthDateErrorHandler = ''" v-model="birthday" type="date" name="birth" id="birth" />
					</div>
				</div>
			</div>
      <div id="persona-verification" v-if="!this.returningCustomer">
        <div class="header">
          Drivers License / State ID Scan
        </div>
        <Persona
            @verificationCompleted="onVerificationCompleted"
            @scanNow="personaModalVisible = false"
            @scanLater="scanLaterClicked"
            :modalVisible="personaModalVisible"
            :personaEnvironment="personaEnvironment"
            :personaTemplateId="personaTemplateId"
            :personaEnvironmentId="personaEnvironmentId"
        />
      </div>
			<div v-if="verified" class="verified">
				<p>User verified.</p>
			</div>

			<div class="save-container">
				<button @click="saveBtnHandler">SAVE AND CONTINUE</button>
			</div>
		</div>
		<div v-else class="form-details">
			<div class="verification-pending">
				<h5 class="pending">Verification Pending</h5>
				<p>(we will contact you should we need more information)</p>
			</div>

			<a id="veri-edit" @click="isEdit = true">EDIT</a>
		</div>
	</div>
</template>

<script>
	import * as Toastr from "toastr";
	import JsonApi from "../../../../vendor/json-api";

	import Persona from "./persona.vue"

	export default {
		props: {
			user: { type: Object },
			addresses: { type: Array },
			securityCheck: { type: Object },
			personaEnvironment: { type: String },
			personaTemplateId: { type: String },
			personaEnvironmentId: { type: String },
			verificationOpened: { type: Boolean },
      returningCustomer: { type: Boolean },
		},
		components: {
			Persona
		},
		data() {
			return {
				isEdit: true,
				trulioo_verification: this.user.trulioo_verification.data,
				phone_verification: {
					code: this.user.phone_verification_code,
					verified: this.user.phone_verified,
					verified_at: this.user.phone_verified_at
				},
				code: null,
				sendText: "Click here to send code",
				birthday: this.user.birthday,
				isSent: false,
				birthDateErrorHandler: "",
				personaVerified: false,
				skipPersona: false,
				personaModalVisible: false,
			};
		},
		watch: {
			verificationOpened() {
				if(this.personaVerified || this.returningCustomer) {
					this.saveBtnHandler();
				}
			}
		},
    computed: {
      verified() {
        return this.returningCustomer || this.personaVerified
      }
    },
		mounted() {
			if ($("#send-sms")[0]) {
				$("#send-sms")[0].antispam = false;
			}

			this.personaVerified = (this.user.persona_verified_at != null);
		},
		methods: {
			showForm: function() {
				this.isEdit = true;
			},
			verifyPhoneRequest: function() {
				const button = $("#send-sms");
				if (button[0].antispam == true) {
					Toastr.error("SMS code can be only sent once per minute!");
					return;
				}
				button[0].antispam = true;

				const that = this;
				this.sendText = "Click here to RE-send code";
				this.isSent = true;

				$.ajax({
					method: "PUT",
					data: {
						user: {
							phone: this.addresses[0].phone_number
						}
					},
					url: this.user.links.verify_phone_request,
					success: function(data) {
						Toastr.success("Verification code sent successful!");
						var deserialized = JsonApi.deserialize(data.user);
						that.setPhoneVerificationData(deserialized);
					},
					error: function(data) {
						Toastr.error(
							data.responseJSON.errors_full.join(),
							"Phone verification error"
						);
					}
				});

				setTimeout(function() {
					button[0].antispam = false;
				}, 60000);
			},
			setPhoneVerificationData: function(data) {
				this.phone_verification = {
					code: data.phone_verification_code,
					verified: data.phone_verified,
					verified_at: data.phone_verified_at
				};
			},
			verifyPhone: function() {
				var context = this;

				$.ajax({
					method: "PUT",
					data: { code: context.code },
					url: context.user.links.verify_phone,
					success: function(data) {
						context.code = "";
						context.user.phone_verified = true;
						var deserialized = JsonApi.deserialize(data.user);
						context.setPhoneVerificationData(deserialized);
						Toastr.success("Phone number verified successfuly!");
					},
					error: function(data) {
						Toastr.info("Can't verify your phone number right now? Please check your email for further information.");
					}
				});
			},
			saveBtnHandler: function() {
				if ((!this.birthday && !this.user.birthday) || this.birthday === "") {
					Toastr.error("Birthday field cannot be empty.");
					this.birthDateErrorHandler = 'error'
					return;
				}

				if (!this.user.birthday || this.user.birthday != this.birthday) {
					$.ajax({
						method: "PUT",
						data: {
							// this params is required
							account: {
								first_name: this.user.first_name
							},
							user: {
								birthday: this.birthday,
								use_shipping_as_billing_address: true
							}
						},
						url: this.user.links.my_account,
						success: function(data) {},
						error: function(data) {
							Toastr.error("Error adding birthday to db!");
							return;
						}
					});
				}

        if(!this.returningCustomer) {
          if(!this.personaVerified && !this.skipPersona) {
            this.personaModalVisible = true;
            return;
          }
        }

				if (!this.phone_verification.verified) {
					this.verifyPhone();
				}

				this.securityCheck.verification = true;
				this.isEdit = false;

				const billingBox = $("#checkout-billing");
				billingBox.css("height", billingBox[0].scrollHeight);

				const veriBox = $("#checkout-verification");

				veriBox.css("height", "max-content");

				setTimeout(() => {
					billingBox.css("height", "max-content");
					billingBox.css("overflow", "visible");
				}, 2000);
			},
			onVerificationCompleted: function() {
				this.personaVerified = true;

				$.ajax({
					method: "PUT",
					data: {},
					url: this.user.links.verify_persona,
					success: function(data) {},
					error: function(data) {
						Toastr.error("Error saving Persona verification to db!");
						return;
					}
				});

				this.saveBtnHandler();
			},
			scanLaterClicked: function() {
				this.skipPersona = true;
				this.saveBtnHandler();
			},
		}
	};
</script>

<style lang="scss">
.error {
	color: red;
	input {
		border: solid 1px red;
	}
}
</style>
