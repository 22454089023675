<template lang="html">
  <a @click="$emit('toggle-modal')">GO AHEAD. TELL ALL.</a>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
