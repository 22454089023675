<template>
  <div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="account-first-name">First name</label>
          <input type="text" class="form-control" id="account-first-name" placeholder="First name" v-model="account.first_name" v-bind:class="{ 'is-invalid': errors.account.first_name }" v-on:keyup.enter="update">
          <div v-if="errors.account.first_name" class="invalid-feedback">{{ errors.account.first_name.join(', ') }}</div>
        </div>

        <div class="form-group">
          <label for="account-last-name">Last name</label>
          <input type="text" class="form-control" id="account-last-name" placeholder="Last name" v-model="account.last_name" v-bind:class="{ 'is-invalid': errors.account.last_name }" v-on:keyup.enter="update">
          <div v-if="errors.account.last_name" class="invalid-feedback">{{ errors.account.last_name.join(', ') }}</div>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <label for="vendor-phone">Phone</label>
          <div>
            <span class="phone-prefix">
              +1
            </span>
            <input
              type="text"
              class="form-control"
              id="vendor-phone"
              placeholder="Phone"
              v-model="vendor.phone"
              v-bind:class="{ 'is-invalid': errors.vendor.phone }"
              v-on:keyup.enter="update"
            />
          </div>
          <small class="text-black-50 fs"
            >Please enter your phone number with no spaces: 4132135672
          </small>
          <div v-if="errors.vendor.phone" class="invalid-feedback">{{ errors.vendor.phone.join(', ') }}</div>
        </div>

        <div class="form-group">
          <label for="account-email">Email address</label>
          <input type="email" class="form-control" id="account-email" placeholder="Your email address" v-model="account.email" v-bind:class="{ 'is-invalid': errors.account.email }" v-on:keyup.enter="update">
          <div v-if="errors.account.email" class="invalid-feedback">{{ errors.account.email.join(', ') }}</div>
          <small class="text-black-50 fs">You will recieve verification email on the new email address, before the changes occur.</small>
        </div>
      </div>

    </div>


    <button type="button" class="btn btn-primary" v-on:click='update'>Save changes</button>


  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from 'toastr';

export default {
  props: {
    vendor: {type: Object, required: true},
    account: {type: Object, required: true},
    path: {type: String, required: true}
  },
  data: function() {
    return {
      errors: {
        vendor: {},
        account: {}
      }
    }
  },
  methods: {
    update() {
      var that = this;

      $.ajax({
        method: 'PUT',
        data: {
          account: that.account,
          vendor: that.vendor
        },
        url: that.path,
        success: function(data) {
          that.errors.account = {}
          that.errors.vendor = {}

          Toastr.success('Account updated successful!');
          that.$parent.account = JsonApi.deserialize(data.account);
          that.$parent.vendor = JsonApi.deserialize(data.vendor);
        },
        error: function(data) {
          that.errors.account = data.responseJSON.errors.account
          that.errors.vendor = data.responseJSON.errors.vendor
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving account');
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  span.phone-prefix{
    position: absolute;
    color: #495057;
    font-family: Roboto,serif;
    font-weight: 300;
    font-size: .8125rem;
    height: 38px;
    line-height: 38px;
    padding-left: 15px;
    pointer-events: none;
  }
  input#vendor-phone{
    padding-left: 45px;
  }
</style>