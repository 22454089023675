<template>
  <div class="modal fade" id="show-modal-review" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class='modal-title'> Your review</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">close x</span>
          </button>
        </div>
        <div class="modal-body">
          <dl>
            <dt>Rating: <i class="fa-star" v-for='rating in ratings' :class='starClass(rating)'></i></dt>
            <dd></dd>

            <dt>Rented this for:</dt>
            <dd>{{userReview.occasion_text}}</dd>

            <dt>Product condition upon arrival:</dt>
            <dd>{{userReview.condition_text}}</dd>

            <dt>Was this product:</dt>
            <dd>{{userReview.satisfaction_text}}</dd>

            <dt>Did the product arrive on time?</dt>
            <dd>{{userReview.on_time_text}}</dd>

            <dt>Would you rent from this vendor again?</dt>
            <dd>{{userReview.rent_again_text}}</dd>

            <dt>Would you recommend this jewelry to a friend?</dt>
            <dd>{{userReview.recommend_text}}</dd>

            <dt>Anything you would like to add about this product:</dt>
            <dd>{{userReview.product_comment}}</dd>

            <dt>Anything you would like to add about this vendor:</dt>
            <dd>{{userReview.vendor_comment}}</dd>

            <div v-if='userReview.file_variants'>
              <img :src='userReview.file_variants.thumb' class="img-thumbnail image-preview" />
            </div>

          </dl>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['userReview'],
  data() {
    return {
      ratings: [1, 2, 3, 4, 5],
      rating: 0,
    }
  },
  updated() {
    this.rating = this.userReview.rating
  },
  watch: {
    rating() {
      FontAwesome.dom.i2svg()
    }
  },
  methods: {
    starClass(starValue) {
      if (!_.isEmpty(this.userReview)) {
        return this.userReview.rating >= starValue ? 'fas' : 'far'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-preview {
  max-width: 100%;
}

</style>
