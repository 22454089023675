<template>
  <div class="modal fade" id="show-modal-review" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class='modal-title'> Your review</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">close x</span>
          </button>
        </div>
        <div class="modal-body">
          <dl>
            <dt>Product condition upon arrival:</dt>
            <dd>{{vendorReview.condition_text}}</dd>

            <dt>Was the package damaged?</dt>
            <dd>{{vendorReview.package_damaged_text}}</dd>

            <dt>Anything you would like to add about this product:</dt>
            <dd>{{vendorReview.product_comment}}</dd>
          </dl>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from 'toastr';

export default {
  props: ['vendorReview']
}
</script>
