<template>
  <div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <div class="h4 m-0">Identity Verification </div>

        <div class="mt-3">
          <span class="badge px-4 py-2 mb-3" v-bind:class="{ 'badge-success': trulioo.status == 'match', 'badge-warning': trulioo.status == 'nomatch', 'badge-secondary' : trulioo.status == null}">
            <div class="h6 mb-0">
              <span v-if="trulioo.status == 'match'">Verified</span>
              <span v-if="trulioo.status == 'nomatch'">Not Matching</span>
              <span v-if="trulioo.status == null">Not Verified</span>
            </div>
          </span>
          <div>
            <em class="text-black-50">Verification is made using your personal data across our partners database to prove you have entered correct data.</em>
          </div>

        </div>
      </div>

      <div class="col-md-4 mb-3">
        <div class="h4 m-0">Email Verification</div>

        <div class="mt-3">
          <span class="badge px-4 py-2 mb-3" v-bind:class="{ 'badge-success': account.confirmed, 'badge-warning': account.confirmed == false}">
            <div class="h6 m-0">
              <span v-if="account.confirmed == true">Verified</span>
              <span v-if="account.confirmed == false">Not Verified</span>
            </div>
          </span>

          <div v-if="account.unconfirmed_email">
            <p>Unconfirmed email: <strong>{{account.unconfirmed_email}}</strong></p>
            <em class="text-black-50">We've sent confirmation email. <br />Please check your email and follow the instructions to confirm this email.</em>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-3">
        <div class="h4 m-0">Phone Verification</div>
        <div class="mt-3">
          <span class="badge px-4 py-2 mb-3" v-bind:class="{ 'badge-success': user.phone_verified, 'badge-warning': user.phone_verified == false}">
            <div class="h6 m-0">
              <span v-if="user.phone_verified == true">Verified</span>
              <span v-if="user.phone_verified == false">Not Verified</span>
            </div>
          </span>

          <div v-if="user.phone_verified == false">
            <p>Unverified phone: <strong>{{user.phone}}</strong></p>
            <em class="text-black-50">We've sent message with code to this number. <br />Please check your messages and enter the recieved code in the input bellow to confirm this phone.</em>

            <div class="input-group mt-2">
              <input type="text" class="form-control" placeholder="Verification code" v-model="code" v-on:keyup.enter="verify">
              <div class="input-group-append">
                <button class="btn btn-primary btn-verification" type="button" v-on:click='verify'>Verify</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api";
import * as Toastr from 'toastr';

export default {
  props: {
    trulioo: Object,
    account: Object,
    user: Object,
    path: String
  },
  data: function() {
    return {
      code: ''
    }
  },
  methods: {
    verify: function() {
      var that = this;

      $.ajax({
        method: 'PUT',
        data: { code: that.code },
        url: that.path,
        success: function(data) {
          that.code = '';
          Toastr.success('Phone number verified successful!');
          that.$parent.user = JsonApi.deserialize(data.user);
        },
        error: function(data) {
          Toastr.error(data.responseJSON.error, 'Error verifing phone number');
        }
      });
    }
  }
}
</script>

<style>
.input-group-append .btn-verification {
  text-transform: none;
  line-height: 1em;
  font-family: 'Roboto', serif;
}
</style>
