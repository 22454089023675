<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          <label for="account-first-name">First name</label>
          <input
            type="text"
            class="form-control"
            id="account-first-name"
            placeholder="First name"
            v-model="account.first_name"
            v-bind:class="{ 'is-invalid': errors.account.first_name }"
            v-on:keyup.enter="update"
          />
          <div v-if="errors.account.first_name" class="invalid-feedback">
            {{ errors.account.first_name.join(", ") }}
          </div>
        </div>

        <div class="form-group">
          <label for="account-last-name">Last name</label>
          <input
            type="text"
            class="form-control"
            id="account-last-name"
            placeholder="Last name"
            v-model="account.last_name"
            v-bind:class="{ 'is-invalid': errors.account.last_name }"
            v-on:keyup.enter="update"
          />
          <div v-if="errors.account.last_name" class="invalid-feedback">
            {{ errors.account.last_name.join(", ") }}
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          <label for="account-email">Email address</label>
          <input
            type="email"
            class="form-control"
            id="account-email"
            placeholder="Your email address"
            v-model="account.email"
            v-bind:class="{ 'is-invalid': errors.account.email }"
            v-on:keyup.enter="update"
          />
          <div v-if="errors.account.email" class="invalid-feedback">
            {{ errors.account.email.join(", ") }}
          </div>
          <small class="text-black-50 fs"
            >You will recieve verification email on the new email address,
            before the changes occur.</small
          >
        </div>
      </div>

      <div class="col-sm-6 col-md-4">
        <div class="store-credit">
          Store credit: {{ user.store_credit_formatted }}
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          <label for="user-dln">Driver's License Number</label>
          <input
            type="text"
            class="form-control"
            id="user-dln"
            placeholder="Drivers License Number"
            v-model="user.dln"
            v-bind:class="{ 'is-invalid': errors.user.dln }"
            v-on:keyup.enter="update"
          />
          <div v-if="errors.user.dln" class="invalid-feedback">
            {{ errors.user.dln.join(", ") }}
          </div>
        </div>

        <div class="form-group">
          <label for="user-dln-state">Driver's License State</label>
          <b-form-select
            id="user-dln-state"
            v-model="user.dln_state"
            :options="states"
            value-field="abbreviation"
            text-field="name"
            class="mb-3"
            v-bind:class="{ 'is-invalid': errors.user.dln_state }"
          />
          <div v-if="errors.user.dln_state" class="invalid-feedback">
            {{ errors.user.dln_state.join(", ") }}
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4">
        <div class="form-group">
          <label for="user-birthday">Birthday</label>
          <input
            type="text"
            class="form-control"
            v-mask="'##/##/####'"
            placeholder="mm/dd/yyyy"
            v-model="birthday"
            v-bind:class="{ 'is-invalid': errors.user.birthday }"
            v-on:keyup.enter="update"
          />
          <div v-if="errors.user.birthday" class="invalid-feedback d-block">
            {{ errors.user.birthday.join(", ") }}
          </div>
        </div>
        <div class="form-group">
          <label for="user-phone">Cell phone number</label>
          <div>
            <span class="phone-prefix">
              +1
            </span>
            <input
              type="text"
              class="form-control"
              id="user-phone"
              placeholder="Phone Number"
              v-model="user.phone"
              v-bind:class="{ 'is-invalid': errors.user.phone }"
              v-on:keyup.enter="update"
            />
          </div>
          <small class="text-black-50 fs"
            >Please enter your phone number with no spaces: 4132135672
          </small>
          <div v-if="errors.user.phone" class="invalid-feedback">
            {{ errors.user.phone.join(", ") }}
          </div>
        </div>
      </div>
    </div>
    <!-- <input  im-insert="false"> -->

    <button type="button" class="btn btn-primary" v-on:click="update">
      Save changes
    </button>

    <hr />

    <div v-if="account.braintree_subscriber" class="subscription-wrapper">
      <p>
        You are currently subscribed to our monthly jewelry rental plan. To
        cancel your subscription, please click the button below. In order for
        the cancellation to become effective, you must first return all rental
        jewelry that is in your possession. Cancellation will become effective
        as of the end of the current billing cycle.
      </p>
      <a
        class="cancel-subscription"
        rel="nofollow"
        data-method="post"
        href="/my-account/subscriptions/cancel"
        >CANCEL SUBSCRIPTION</a
      >
    </div>
    <div v-else class="subscription-wrapper">
      <p>
        To sign up for monthly jewelry subscription, please
        <a class="click-here-link" href="/rent-jewelry?plan=sub-box"
          >click here.</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api";
import * as Toastr from "toastr";
var UsaStates = require("usa-states").UsaStates;
import * as moment from "moment";

export default {
  props: {
    user: { type: Object, required: true },
    account: { type: Object, required: true },
    path: { type: String, required: true },
  },
  data: function () {
    return {
      errors: {
        user: {},
        account: {},
      },
      birthday: "",
    };
  },
  watch: {
    birthday(value) {
      this.user.birthday = moment(value).format("YYYY-MM-DD");
    },
  },
  computed: {
    states() {
      var usStates = new UsaStates();
      return usStates.states;
    },
  },
  mounted: function () {
    this.birthday = moment(this.user.birthday).format("MM/DD/YYYY");
  },
  methods: {
    update() {
      var that = this;

      $.ajax({
        method: "PUT",
        data: {
          account: that.account,
          user: that.user,
        },
        url: that.path,
        success: function (data) {
          that.errors.account = {};
          that.errors.user = {};
          Toastr.success("Account updated successful!");
          that.$parent.trulioo_verification = JsonApi.deserialize(
            data.trulioo_verification
          );
          that.$parent.account = JsonApi.deserialize(data.account);
          that.$parent.user = JsonApi.deserialize(data.user);
        },
        error: function (data) {
          that.errors.account = data.responseJSON.errors.account;
          that.errors.user = data.responseJSON.errors.user;
          Toastr.error(
            data.responseJSON.errors_full.join(),
            "Error saving account"
          );
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-wrapper {
  p {
    font-family: "Roboto Slab", serif;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    color: #000000;
    padding-bottom: 6.25px;
  }

  .cancel-subscription {
    border: solid 2px black;
    padding: 0.5em;
    &:hover {
      color: white;
      background: black;
    }
  }
  .click-here-link {
    &:hover {
      color: black;
      opacity: 0.7;
    }
  }
}
span.phone-prefix{
  position: absolute;
  color: #495057;
  font-family: Roboto,serif;
  font-weight: 300;
  font-size: .8125rem;
  height: 38px;
  line-height: 38px;
  padding-left: 15px;
  pointer-events: none;
}
input#user-phone{
  padding-left: 45px;
}
</style>
