import Vue from 'vue/dist/vue.esm';
import JsonApi from '../../../vendor/json-api';

import AdminProducts from '../../../admin/controllers/admin_products';

export default class VendorsProducts extends AdminProducts {
  new() {
    this.shared();
  }

  create() {
    this.new();
  }

  edit() {
    super.edit();
    this.shared();
  }

  specifications() {
    this.edit();
  }

  update() {
    this.edit();
  }

  shared() {
    var that = this;
    that.setThumbDefaults();

    $('#product_title').on('keyup', function() {
      that.setThumbTitle($(this).val());
    });

    $('#product_short_description').on('keyup', function() {
      that.setThumbSubTitle($(this).val());
    });

    $('#product_image_attributes_file').on('change', function() {
      const file = this.files[0];

      if (file) {
        const reader = new FileReader();
        $('#default-img').css('display', 'none');
        $('#preview').css('display', 'block');

        reader.addEventListener('load', function() {
          $('#preview').attr('src', this.result);
        });
        reader.readAsDataURL(file);
      }
    });

    let prevo = document.querySelector('.preview-overlap');
    if(prevo){
      prevo.addEventListener('click', () =>
        document.getElementById('product_image_attributes_file').click()
      );
    }
  }

  setThumbDefaults() {
    this.setThumbTitle($('#product_title').val());
    this.setThumbSubTitle($('#product_short_description').val());
  }

  setThumbTitle(title) {
    title = title === '' ? '**Enter name in title to preview it**' : title;
    $('.product-thumb a.title').text(title);
  }

  setThumbSubTitle(title) {
    title = title === '' ? '**Enter text in sub title to preview it**' : title;
    $('.product-thumb .details .subtitle').text(title);
  }
}
