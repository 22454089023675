<template>
  <div>
    <transition name="slide-fade" v-on:after-leave="afterLeave">
      <div class="mb-30" v-if="show">

        <h4>
          <span class="badge badge-dark p-2">
            Unit #{{item.id}} - {{item.variant.data.name}}
          </span>
        </h4>

        <span class="d-inline-block radio-custom" v-for="(value, label) in item.availableStatuses" v-bind:class="{ 'radio-success': label == 'available', 'radio-danger': ['maintenance', 'stopped'].indexOf(label) > -1}">>
          <input type="radio" :id="'unit_' + item.id + '_status_' + value" :name="'unit[' + item.id + '][status]'" v-model="item.status" :value="label">
          <label :for="'unit_' + item.id + '_status_' + value">{{label}}</label>
        </span>

        <div class="form-group string optional">
          <label class="form-control-label string optional" :for="sku_id">Stock Keeping Unit</label>
          <input class="form-control string optional" type="text" v-model="item.sku" :id="sku_id" v-bind:class="{ 'is-invalid': errors.sku }" v-on:keyup.enter="saveItem">
          <small class="form-text text-muted">Vendor SKU used for personal indetification of the unit</small>
          <div v-if="errors.sku" class="invalid-feedback">{{ errors.sku.join(', ') }}</div>
        </div>

        <div class="form-group text optional">
          <label class="form-control-label text optional" :for="notes_id">Additional notes</label>
          <textarea class="form-control text optional" rows="3" v-model="item.notes" :id="notes_id" v-bind:class="{ 'is-invalid': errors.notes }"></textarea>
          <small class="form-text text-muted">Additional information related to the unit. Will be displayed on the vendor on order fulfillment.</small>
          <div v-if="errors.notes" class="invalid-feedback">{{ errors.notes.join(', ') }}</div>
        </div>

        <div class="mt-2">
          <button type="button" class="btn btn-outline btn-outline-secondary btn-default mt-auto mr-1" v-on:click='saveItem'>Save</button>
          <button type="button" class="btn btn-outline btn-danger mt-auto" v-on:click='removeItem'>Remove</button>
          <a class='btn btn-outline btn-outline-secondary btn-default' :href='item.links.absences'>Availability</a>

        </div>

        <hr>

      </div>
    </transition>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api"
import * as Toastr from 'toastr';

export default {
  props: {
    item: Object,
    createpath: String
  },
  data: function () {
    return {
      show: true,
      errors: {}
    }
  },
  computed: {
    hasErrors: function () {
      return Object.keys(this.errors).length != 0
    },
    sku_id: function() {
      return 'unit_' + this.item.id + '_sku';
    },
    notes_id: function() {
      return 'unit_' + this.item.id + '_notes';
    },
    unit_fields: function() {
      return {
        variant_id: this.item.variant.data.id,
        status: this.item.status,
        sku: this.item.sku,
        notes: this.item.notes,
      }
    }
  },
  methods: {
    saveItem() {
      if (this.item.persisted) {
        this.updateItem();
      } else {
        this.createItem()
      }
    },
    createItem() {
      var that = this;
      $.ajax({
        method: 'POST',
        data: {
          unit: that.unit_fields
        },
        url: that.createpath,
        success: function(data) {
          var units = that.$parent.units;
          units.splice(units.indexOf(that.item), 1)
          var deserialized = JsonApi.deserialize(data)
          units.push(deserialized)
          that.errors = {}
          Toastr.success('Unit #' + deserialized.id + ' saved!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving unit #' + that.item.id);
        }
      });
    },
    updateItem() {
      var that = this;
      $.ajax({
        method: 'PUT',
        data: {
          unit: that.unit_fields
        },
        url: that.item.links.self,
        success: function(data) {
          that.errors = {}
          Toastr.success('Unit #' + that.item.id + ' saved!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving unit #' + that.item.id);
        }
      });
    },
    removeItem() {
      var that = this;
      if (!that.item.persisted) {
        that.show = false;
        return;
      }

      $.ajax({
        method: 'DELETE',
        url: that.item.links.self,
        success: function(data) {
          that.show = false
          that.errors = {}
          Toastr.success('Unit #' + that.item.id + ' removed!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error removing unit #' + that.item.id);
        }
      });
    },
    afterLeave: function() {
      var units = this.$parent.units;
      units.splice(units.indexOf(this.item), 1)
    }
  }
}
</script>

<style>
h4 .badge-dark {
  color: #fff;
  background-color: #526069;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
</style>
