import Vue from "vue/dist/vue.esm"
import JsonApi from "../../../../vendor/json-api"

import AdminProductsImages from "../../../../admin/controllers/products/images";


export default class VendorsProducts extends AdminProductsImages {
  index() {
    super.index();
  }
}
