import Products from "./products";
import Slick from "slick-carousel";

export default class extends Products {
  index() {
    super.index();

    $(".reviews-carousel").slick({
      arrows: false,
      dots: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 20000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }

  show() {
    super.index();
  }
}
