<template>
  <div class="order-item">
    <div class="row">
      <div class="col-2">
        <a :href="product.links.public" class="a-image" target="_blank">
          <img :src="order_item.image_urls.thumb" />
        </a>
      </div>
      <div class="col-6">
        <a :href="order_item.product.data.links.public" class="a-title">
          {{ order_item.product.data.title }}
        </a>
        <div class="sub text-black-50">
          {{ order_item.product.data.short_description }}
        </div>
      </div>

      <div class="col-4 text-center" v-if="!order_expired">
        <div v-if="order_item.status == 'cancelled'" >
          <span class="badge badge-grey px-5 py-3">Cancelled</span>
        </div>
        <div v-if="sub_order.status == 'returned'">
          <div v-if="order_item.vendor_review.data">
            <button
              class="btn btn-outline-primary"
              @click="showReview"
              data-toggle="modal"
              data-target="#show-modal-review"
            >
              See your review
            </button>
          </div>
          <div v-else>
            <button
              class="btn btn-primary"
              @click="reviewItem"
              data-toggle="modal"
              data-target="#modal-review"
            >
              Review this item
            </button>
          </div>
        </div>
        <div v-if="sub_order.status == 'pending'">
          <div class="mb-3">
            <button
              class="btn btn-outline-primary"
              @click="cancelItem"
              v-if="this.order_item.status == 'pending'"
            >
              Cancel Item
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from "toastr";

export default {
  props: {
    order_item: { type: Object, required: true },
    sub_order: { type: Object, required: true },
    order_expired: { type: Boolean, required: true },
  },
  data() {
    return {
      status: this.order_item.status,
      product: this.order_item.product.data,
    };
  },
  computed: {},
  methods: {
    cancelItem() {
      let that = this;
      if(confirm('Are you sure you want to cancel this order item? This action can\'t be undone')) {
        $.ajax({
          url: this.order_item.links.cancel,
          method: "PATCH",
          data: {
            order_item: { status: "cancelled" },
          },
          success(response) {
            console.log(that);
            that.$emit('update-sub-order', JsonApi.deserialize(response))
            Toastr.success("Item has been cancelled");
          },
          error(response) {
            Toastr.error(
                response.responseJSON.errors_full.join(),
                "Item was not cancelled"
            );
          },
        });
      }
    },
    reviewItem() {
      this.$emit(
        "review-modal",
        this.order_item.links.vendor_review,
        this.order_item.id
      );
    },
    showReview() {
      this.$emit("show-review-modal", this.order_item.vendor_review.data);
    },
  },
};
</script>
