// https://github.com/milroyfraser/sarala-json-api-data-formatter
import _ from "lodash"
import inflection from "inflection";

class Formatter {
  constructor() {
    this.data = {}
    this.includes = null
    this.fields = null
    this.includedData = []
  }

  includeOnly(includes = []) {
    this.includes = includes

    return this
  }

  filterFields(fields = {}) {
    this.fields = fields

    return this
  }

  shouldIncludeRelation(relation) {
    if (this.includes === null) {
      return true
    }

    return _.indexOf(this.includes, relation) > 0
  }

  shouldIncludeField(relation, field) {
    if (this.fields === null) {
      return true
    }

    if (!this.fields.hasOwnProperty(relation)) {
      return true
    }

    if (_.indexOf(this.fields[relation], field) !== -1) {
      return true
    }

    return false
  }

  deserialize(data) {
    this.data = data

    if (_.isArray(data.data)) {
      return this.deserializeCollection(data)
    }

    return this.deserializeOne(data.data)
  }

  deserializeOne(data) {
    if (_.isUndefined(data)) {
      return
    }
    let formatted = {}
    formatted.id = data.id
    formatted.type = data.type

    if (data.links) {
      formatted.links = data.links
    }

    if (data.meta) {
      formatted.meta = data.meta
    }

    let thiss = this

    _.forOwn(data.attributes, (value, key) => {
      if (thiss.shouldIncludeField(data.type, key)) {
        formatted[inflection.underscore(key, true)] = value
      }
    })

    if (data.relationships) {
      formatted.relationships = []

      for (var key in data.relationships) {
        if (this.shouldIncludeRelation(key)) {
          formatted.relationships.push(key)
          let relationship = this.mapAndKillProps(data.relationships[key], {}, ["links", "meta"]).to

          if (_.isArray(data.relationships[key].data)) {
            relationship.dataCollection = true
            relationship.data = this.resolveRelationCollection(data.relationships[key].data)
          } else {
            relationship.data = this.resolveRelation(data.relationships[key].data)
          }

          formatted[key] = relationship
        }
      }
    }

    return formatted
  }

  deserializeCollection(data) {
    const thiss = this
    data.dataCollection = true

    data.data = _.map(data.data, item => {
      return thiss.deserializeOne(item)
    })

    return data
  }

  resolveRelation(data) {
    if (_.isUndefined(data) || _.isNull(data)) { return }
    if (_.isUndefined(data.id)) { return }

    return this.deserializeOne(_.find(this.data.included, data))
  }

  resolveRelationCollection(relations) {
    return _.map(relations, relation => {
      return this.resolveRelation(relation)
    })
  }

  mapAndKillProps(from, to, props) {
    _.each(props, prop => {
      if (from.hasOwnProperty(prop)) {
        to[prop] = from[prop]
        delete from[prop]
      }
    })

    return { from, to }
  }

  isSerializeableCollection(data) {
    return data.hasOwnProperty("dataCollection") && data.dataCollection === true && _.isArray(data.data)
  }

  serialize(data) {
    this.includedData = []
    let serialized = {}

    if (this.isSerializeableCollection(data)) {
      serialized = this.serializeCollection(data)
    } else {
      serialized.data = this.serializeOne(data)
    }

    if (this.includedData.length) {
      serialized.included = this.includedData
    }

    return serialized
  }

  serializeOne(data) {
    let serialized = {
      attributes: {},
      relationships: {}
    }

    const mapAndKilled = this.mapAndKillProps(data, serialized, ["id", "type", "links", "meta"])

    data = mapAndKilled.from
    serialized = mapAndKilled.to

    let thiss = this

    if (data.hasOwnProperty("relationships")) {
      _.forEach(data.relationships, relationship => {
        if (thiss.shouldIncludeRelation(relationship)) {
          let relationshipData = thiss.mapAndKillProps(data[relationship], {}, ["links", "meta"]).to

          if (thiss.isSerializeableCollection(data[relationship])) {
            relationshipData.data = thiss.serializeRelationshipCollection(data[relationship].data)
          } else {
            relationshipData.data = thiss.serializeRelationship(data[relationship].data)
          }

          serialized.relationships[relationship] = relationshipData
        }

        delete data[relationship]
      })

      delete data.relationships
    }

    _.forOwn(data, (value, key) => {
      if (thiss.shouldIncludeField(serialized.type, key)) {
        serialized.attributes[key] = value
      }
    })

    if (_.isEmpty(serialized.relationships)) {
      delete serialized.relationships
    }

    return serialized
  }

  serializeCollection(data) {
    const mapAndKilled = this.mapAndKillProps(data, {}, ["links", "meta"])

    data = mapAndKilled.from
    let serialized = mapAndKilled.to

    serialized.data = _.map(data.data, item => {
      return this.serializeOne(item)
    })

    return serialized
  }

  serializeRelationship(data) {
    const serialized = this.serializeOne(data)
    this.addToIncludes(serialized)

    return { type: serialized.type, id: serialized.id }
  }

  serializeRelationshipCollection(data) {
    const thiss = this

    return _.map(data, item => {
      return thiss.serializeRelationship(item)
    })
  }

  addToIncludes(data) {
    const thiss = this

    if (_.isUndefined(_.find(thiss.includedData, { id: data.id, type: data.type }))) {
      this.includedData.push(data)
    }
  }
}

export default new Formatter()
