<template lang="html">
  <div >
    <div class="step-header">
      wear new jewelry every month. like a boss.
    </div>
    <div class="image-wrapper">
      <img src="~images/app/subscriptions/step-1.webp" @click='nextStep' />
      <div class="text-wrapper">
        <div class="text">
          <h1>every month you get</h1>
          <p>
            pick 3 pieces of fine jewelry
            <br>
            experiment with new designer styles
            <br>
            wear new or hip vintage jewelry
          </p>

          <button type="button" class="btn btn-primary" v-on:click='nextStep'>Got it. Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    nextStep() {
      this.$emit('next-step', '2')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;

  .text-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }

    button {
      color: #fff;
      background: #000;
      border-color: #000;
      text-transform: uppercase;
      font-size: 16px;
      padding: 0.2rem 2.5rem;
    }

    @media (max-width: 991px) {
      width: 45%;
      h1 { font-size: 24px; }
      p { font-size: 14px; }
      button { font-size: 14px; }
    }
    @media (max-width: 767px) {
      width: 50%;
      h1 { font-size: 20px; }
      p { font-size: 13px; }
      button { font-size: 13px; padding: 0.2rem 2rem; }
    }
    @media (max-width: 500px) {
      width: 50%;
      h1 { font-size: 16px; }
      p { font-size: 12px; }
      button { font-size: 12px; padding: 0.1rem 1.3rem; }
    }

    @media (max-width: 410px) {
      position: relative;
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
