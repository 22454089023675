import Vue from "vue/dist/vue.esm"
import JsonApi from "../../../../vendor/json-api"

import AdminProductsUnits from "../../../../admin/controllers/products/units";


export default class VendorsProductsUnits extends AdminProductsUnits {
  index() {
    super.index();
  }
}
