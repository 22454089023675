<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="company-name">Name</label>
          <input type="text" class="form-control" id="company-name"
          placeholder="company name" v-model="company.name" v-bind:class="{ 'is-invalid': errors.company.name }" v-on:keyup.enter="update"> <div v-if="errors.company.name" class="invalid-feedback">{{ errors.company.name.join(', ') }}</div>
        </div>

        <div class="form-group">
          <label for="company-name">Federal Tax ID Number</label>
          <input type="text" class="form-control" id="company-tax_id"
          placeholder="company tax_id" v-model="company.tax_id" v-bind:class="{ 'is-invalid': errors.company.tax_id }" v-on:keyup.enter="update"> <div v-if="errors.company.tax_id" class="invalid-feedback">{{ errors.company.tax_id.join(', ') }}</div>
        </div>

        <div class="form-group">
          <label for="company-name">JBT Number</label>
          <input type="text" class="form-control" id="company-jbt_number"
          placeholder="company jbt_number" v-model="company.jbt_number" v-bind:class="{ 'is-invalid': errors.company.jbt_number }" v-on:keyup.enter="update"> <div v-if="errors.company.jbt_number" class="invalid-feedback">{{ errors.company.jbt_number.join(', ') }}</div>
        </div>
      </div>

    </div>


    <button type="button" class="btn btn-primary" v-on:click='update'>Save changes</button>


  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from 'toastr';

export default {
  props: {
    account: {type: Object, required: true},
    company: {type: Object, required: true},
    path: {type: String, required: true}
  },
  data: function() {
    return {
      errors: {
        company: {}
      }
    }
  },

  methods: {
    update() {
      var that = this;

      $.ajax({
        method: 'PUT',
        data: {
          company: that.company
        },
        url: that.path,
        success: function(data) {
          that.errors.company = {}
          Toastr.success('Company updated successful!');
        },
        error: function(data) {
          that.errors.company = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving company');
        }
      });
    }
  }
}
</script>
