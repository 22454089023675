<template>
  <div>
    <transition name="slide-fade" v-on:after-leave="afterLeave">
    <div v-if="show">
      <hr>
      <p class="mt-3 text-center h6">
        <span v-if="isNewAddress">Add new address</span>
        <span v-else>Edit address</span>
      </p>
      <div class="row">
        <div class="col-sm-8">

          <div class="form-group">
            <label :for="id_for('street')">Street & Number</label>
            <input type="text" class="form-control" :id="id_for('street')" placeholder="Street & Number" v-model="address.street" v-bind:class="{ 'is-invalid': errors.address.street }" v-on:keyup.enter="save">
            <div v-if="errors.address.street" class="invalid-feedback">{{ errors.address.street.join(', ') }}</div>
          </div>
        </div>

        <div class="col-6 col-sm-4">
          <div class="form-group">
            <label :for="id_for('number')">Apt. Number</label>
            <input type="text" class="form-control" :id="id_for('number')" placeholder="Apt. Number" v-model="address.number" v-bind:class="{ 'is-invalid': errors.address.number }" v-on:keyup.enter="save">
            <div v-if="errors.address.number" class="invalid-feedback">{{ errors.address.number.join(', ') }}</div>
          </div>
        </div>

        <div class="col-6 col-sm-4">
          <div class="form-group">
            <label :for="id_for('city')">City</label>
            <input type="text" class="form-control" :id="id_for('city')" placeholder="City" v-model="address.city" v-bind:class="{ 'is-invalid': errors.address.city }" v-on:keyup.enter="save">
            <div v-if="errors.address.city" class="invalid-feedback">{{ errors.address.city.join(', ') }}</div>
          </div>
        </div>

        <div class="col-6 col-sm-4">
          <div class="form-group">
            <label :for="id_for('state')">State</label>
            <b-form-select id="state" v-model="address.state" :options="states" value-field="abbreviation" text-field="name" class="mb-3" v-bind:class="{ 'is-invalid': errors.address.state }" />
            <div v-if="errors.address.state" class="invalid-feedback">{{ errors.address.state.join(', ') }}</div>
          </div>
        </div>


        <div class="col-6 col-sm-4">
          <div class="form-group">
            <label :for="id_for('zip')">Zip Code</label>
            <input type="text" class="form-control" :id="id_for('zip')" placeholder="Zip Code" v-model="address.zip" v-bind:class="{ 'is-invalid': errors.address.zip }" v-on:keyup.enter="save">
            <div v-if="errors.address.zip" class="invalid-feedback">{{ errors.address.zip.join(', ') }}</div>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary" v-on:click='save'>Save address</button>
      <button type="button" class="btn btn-outline-secondary" v-on:click='remove' v-if='canRemove'>Remove address</button>

      <hr class='mb-5'>
    </div>
    </transition>
  </div>
</template>

<script>
import JsonApi from "../../../vendor/json-api"
import * as Toastr from 'toastr';
var UsaStates = require('usa-states').UsaStates;

export default {
  props: {
    address: {type: Object, required: true},
    onSave: {type: Function},
    onRemove: {type: Function},
    canRemove: {type: Boolean, default: true }
  },
  data: function() {
    return {
      show: true,
      errors: {
        address: {}
      }
    }
  },
  computed: {
    states() {
      var usStates = new UsaStates();
      return usStates.states;
    },
    isNewAddress: function() {
      return !this.address.persisted;
    },
    saveMethod: function() {
      return this.isNewAddress ? 'POST' : 'PUT';
    },
    addresses: function() {
      return this.address.address_type == 'shipping' ? this.$parent.shipping_addresses : this.$parent.billing_addresses;
    }
  },
  methods: {
    id_for: function(key) {
      return 'address_' + this.address.id + '_' + key
    },
    save() {
      var that = this;

      $.ajax({
        method: that.saveMethod,
        data: {
          address: that.address
        },
        url: that.address.links.self,
        success: function(data) {
          if (that.isNewAddress && that.onSave) {
            var deserialized = JsonApi.deserialize(data);
            that.onSave(that.address, deserialized);
          }

          that.errors.address = {}
          Toastr.success('Address saved successful!');

        },
        error: function(data) {
          that.errors.address = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error saving Address');
        }
      });
    },
    remove() {
      var that = this;
      if (that.isNewAddress) {
        that.show = false;
        return;
      }

      $.ajax({
        method: 'DELETE',
        url: that.address.links.self,
        success: function(data) {
          that.show = false
          that.errors = {}
          Toastr.success('Address #' + that.address.id + ' removed!');
        },
        error: function(data) {
          that.errors = data.responseJSON.errors
          Toastr.error(data.responseJSON.errors_full.join(), 'Error removing address #' + that.address.id);
        }
      });
    },
    afterLeave: function() {
      if (this.onRemove) {
        this.onRemove(this.address);
      }
    }
  }
}
</script>
