<template>
  <div>
    <new-absence v-bind:absence_create_path=absence_create_path v-on:absence-added='absenceAdded' v-bind:unavailable_dates='unavailable_dates'></new-absence>
    <div class='row mt-5'>
    <div class='col-12'>
      <table is='transition-group' name='list' class='table table-hover table-stripped'>
        <tbody>
        <thead>
          <th>ID</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Notes</th>
          <th>Action</th>
        </thead>
        <tr v-for='(absence, index) in absences' :key='absence.id'>
          <td>{{absence.id}}</td>
          <td>{{absence.start_date}}</td>
          <td>{{absence.end_date}}</td>
          <td>
            <div v-if='absence.order_id'>
              Order: #{{ absence.order_id }}
            </div>
            {{absence.notes}}
          </td>
          <td><a href='#' v-if='!absence.order_id' v-on:click='deleteAbsence(absence.id, index)'
              class='btn btn-small btn-danger'>Delete</a></td>
        </tr>
      </tbody>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
import NewAbsence from './new_absence'

export default {
  props: {
    unavailable_dates: {type: Array, required: true},
    absences: {type: Array, required: true},
    absence_create_path: {type: String, required: true}
  },
  components: { NewAbsence },

  data() {
    return { }
  },
  methods: {
    absenceAdded(new_absence) {
      this.absences.unshift(new_absence)
    },

    deleteAbsence(id, index) {
      this.$emit('delete-absence', id, index)
    }
  }
}
</script>

<style>
.list-enter-active {
  transition: all 3s;
}

.list-enter {
  background: #FFFBCC;
}
</style>
