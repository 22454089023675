<template lang="html">
  <div class="">
    <div class="step-header">
      create a free trejours account. we don't talk to strangers.
    </div>

    <div class="fields" v-if="!account.persisted">
      <div class="text-center">
        <fb-signin-button
          :params="fbSignInParams"
          @success="onSignInSuccess"
          @error="onSignInError">
          Sign in with Facebook
        </fb-signin-button>
      </div>

      <div class="text-center info-text">
        Or fill in this form below
      </div>

      <div class="fields-wrapper">

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="register-first-name">First name</label>
              <input type="text" class="form-control" id="register-first-name" placeholder="First name" v-model="account.first_name" v-bind:class="{ 'is-invalid': errors.first_name }" @keyup.enter="register">
              <div v-if="errors.first_name" class="invalid-feedback">{{ errors.first_name.join(', ') }}</div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="register-last-name">Last name</label>
              <input type="text" class="form-control" id="register-last-name" placeholder="Last name" v-model="account.last_name" v-bind:class="{ 'is-invalid': errors.last_name }" @keyup.enter="register">
              <div v-if="errors.last_name" class="invalid-feedback">{{ errors.last_name.join(', ') }}</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="register-email">Email address</label>
          <input type="email" class="form-control" id="register-email" placeholder="Enter email" v-model="account.email" v-bind:class="{ 'is-invalid': errors.email }" @keyup.enter="register">
          <div v-if="errors.email" class="invalid-feedback">{{ errors.email.join(', ') }}</div>
        </div>

        <div class="form-group">
          <label for="register-password">Password</label>
          <input type="password" class="form-control" id="register-password" placeholder="Password" v-model="account.password" v-bind:class="{ 'is-invalid': errors.password }" @keyup.enter="register" autocomplete="new-password">
          <div v-if="errors.password" class="invalid-feedback">{{ errors.password.join(', ') }}</div>
        </div>


        <div class="recaptcha-wrapper" v-show="!recaptchaSuccessfull">
          <vue-recaptcha
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="sitekey">
          </vue-recaptcha>
        </div>


        <button type="submit" class="btn btn-primary btn-block" id="create-account" @click="register">Create account</button>

      </div>
    </div>

    <div class="fields" v-else>
      <div class="text-center">
        <p>You're currently logged in as:</p>
        <h4>{{ account.name }}</h4>
        <h5>{{ account.email }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from 'toastr';
import VueRecaptcha from 'vue-recaptcha';
import JsonApi from "../../../../vendor/json-api"

export default {
  props: {
    account: { type: Object, required: true },
    registerPath: { type: String, required: true },
    sitekey: { type: String, required: true}
  },
  components: {
    VueRecaptcha: VueRecaptcha
  },
  data () {
    return {
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      errors: {},
      status: 'pending',
      recaptchaChallange: null,
      recaptchaSuccessfull: false,
    }
  },
  methods: {
    onSignInSuccess (response) {
      FB.api('/me?fields=name,email,first_name,last_name', data => {
        this.$emit('set-account', data)
      })
      Toastr.success('', 'Place fill up the rest of the form & click Create Account to complete your profile')
      Toastr.success('', 'Successful Facebook authentication')
    },
    onSignInError (error) {
      Toastr.error('Error', 'Unable to authenticate you with Facebook')
    },
    register() {
      if (!this.recaptchaSuccessfull) {
        Toastr.error('Please complete anti-spam verification', 'Registration error');
        return;
      }
      var that = this;

      $.ajax({
        method: 'POST',
        data: {
          account: that.account,
          'g-recaptcha-response': that.recaptchaChallange
        },
        url: that.registerPath,

        success: function(data) {
          that.errors = {}
          that.status = 'complete'
          Toastr.success('Registration successfull!', 'Successfully created trejours account. Please confirm your email with the link you have recieved.');

          that.$emit('set-account', JsonApi.deserialize(data.resource))
        },
        error: function(data) {
          that.recaptchaChallange = null;
          that.recaptchaSuccessfull = false;
          that.resetRecaptcha()
          that.errors = data.responseJSON.errors
          Toastr.error('Please correct the errors in the registration form', 'Registration error');
        }
      })
    },
    onVerify: function (response) {
      this.recaptchaChallange = response;
      this.recaptchaSuccessfull = true;
      // Disabled recaptcha validation done in the controller
    },
    onExpired: function () {
      Toastr.error('Anti-spam verification expired. Please verify again.', 'Registration error');
    },
    resetRecaptcha () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~stylesheets/app/vars';

.fields {
  margin-top: 30px;
}
.fb-signin-button {
  display: inline-block;
  padding: 10px 80px;
  color: #fff;
  cursor: pointer;
  border: 2px solid #fff;
  text-align: center;
  background: #3C5A99;
  font-weight: 300;
  font-family: $font-roboto;
  font-size: 14px;
  text-transform: uppercase;
}

.info-text {
  font-family: $font-roboto;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px 0;
}

.fields-wrapper {
  margin: 0 20%;

  @media (max-width: 901px) {
    margin: 0 10%;
  }

  @media (max-width: 700px) {
    margin: 0;
  }
}

.btn-primary {
  background: #000;
  color: #fff;
  margin-top: 10px;
  border: 0;
}

@media (max-width: 500px) {
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: .8rem;
  }
}
h4 {}
</style>
