const typeData = [
  {
    id: 'type-all',
    text: 'All Jewelry',
    check: true,
  },
  {
    id: 'type-rings',
    text: 'Rings',
    check: false,
  },
  {
    id: 'type-necklaces',
    text: 'Necklaces',
    check: false,
  },
  {
    id: 'type-bracelets',
    text: 'Bracelets',
    check: false,
  },
  {
    id: 'type-earrings',
    text: 'Earrings',
    check: false,
  },
];

const occasionData = [
  {
    id: 'occasion-all',
    text: 'All',
    check: true,
  },
  {
    id: 'occasion-wedding',
    text: 'Wedding',
    check: false,
  },
  {
    id: 'occasion-birthday',
    text: 'Birthday',
    check: false,
  },
  {
    id: 'occasion-professional',
    text: 'Professional/Work',
    check: false,
  },
  {
    id: 'occasion-night-out',
    text: 'Night Out',
    check: false,
  },
  {
    id: 'occasion-everyday',
    text: 'Everyday',
    check: false,
  },
];

const materialData = [
  {
    id: 'material-all',
    text: 'All',
    check: true,
  },
  {
    id: 'material-gold',
    text: 'Gold',
    check: false,
  },
  {
    id: 'material-silver',
    text: 'Silver',
    check: false,
  },
  {
    id: 'material-mixed',
    text: 'Mixed Metals',
    check: false,
  },
  {
    id: 'material-platinum',
    text: 'Platinum',
    check: false,
  },
];

const gemData = [
  {
    id: 'gem-all',
    text: 'All',
    check: true,
  },
  {
    id: 'gem-diamonds',
    text: 'Diamonds',
    check: false,
  },
  {
    id: 'gem-pearls',
    text: 'Pearls',
    check: false,
  },
  {
    id: 'gem-rubies',
    text: 'Rubies',
    check: false,
  },
  {
    id: 'gem-sapphires',
    text: 'Sapphires',
    check: false,
  },
  {
    id: 'gem-aquamarines',
    text: 'Aquamarines',
    check: false,
  },
  {
    id: 'gem-turquoise',
    text: 'Turquoise',
    check: false,
  },
  {
    id: 'gem-onyx',
    text: 'Onyx',
    check: false,
  },
  {
    id: 'gem-none',
    text: 'None',
    check: false,
  },
  {
    id: 'gem-topaz',
    text: 'Topaz',
    check: false,
  },
  {
    id: 'gem-citrine',
    text: 'Citrine',
    check: false,
  },
  {
    id: 'gem-amethyst',
    text: 'Amethyst',
    check: false,
  },
  {
    id: 'gem-mixed',
    text: 'Mixed',
    check: false,
  },
  {
    id: 'gem-other',
    text: 'Other',
    check: false,
  },
];

const styleData = [
  {
    id: 'style-all',
    text: 'All',
    check: true,
  },
  {
    id: 'style-trendy',
    text: 'Trendy',
    check: false,
  },
  {
    id: 'style-classic',
    text: 'Classic',
    check: false,
  },
  {
    id: 'style-edgy',
    text: 'Edgy',
    check: false,
  },
  {
    id: 'style-bold',
    text: 'Bold',
    check: false,
  },
  {
    id: 'style-vintage',
    text: 'Vintage',
    check: false,
  },
];

const colorsData = [
  {
    id: 'colors-all',
    text: 'All',
    check: true,
  },
  {
    id: 'colors-white',
    text: 'White',
    check: false,
  },
  {
    id: 'colors-black',
    text: 'Black',
    check: false,
  },
  {
    id: 'colors-blue',
    text: 'Blue',
    check: false,
  },
  {
    id: 'colors-green',
    text: 'Green',
    check: false,
  },
  {
    id: 'colors-red',
    text: 'Red',
    check: false,
  },
  {
    id: 'colors-pink',
    text: 'Pink',
    check: false,
  },
  {
    id: 'colors-yellow',
    text: 'Yellow',
    check: false,
  },
  {
    id: 'colors-orange',
    text: 'Orange',
    check: false,
  },
  {
    id: 'colors-purple',
    text: 'Purple',
    check: false,
  },
  {
    id: 'colors-multicolor',
    text: 'Multicolor',
    check: false,
  },
  {
    id: 'colors-gold',
    text: 'Gold',
    check: false,
  },
  {
    id: 'colors-silver',
    text: 'Silver',
    check: false,
  },
];

export const panelData = [
  {
    data: typeData,
    id: 'ac-type',
    href: 'section1',
    panelName: 'Type',
  },
  {
    data: occasionData,
    id: 'ac-occasion',
    href: 'section2',
    panelName: 'Occasion',
  },
  {
    data: materialData,
    id: 'ac-material',
    href: 'section3',
    panelName: 'Material',
  },
  {
    data: gemData,
    id: 'ac-gem',
    href: 'section4',
    panelName: 'Gemstones',
  },
  {
    data: styleData,
    id: 'ac-style',
    href: 'section5',
    panelName: 'Style',
  },
  {
    data: colorsData,
    id: 'ac-colors',
    href: 'section6',
    panelName: 'Colors',
  },
];
