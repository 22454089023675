<template>
  <div>
    <div class="focus-title my-10">
      <h1 class="main-text mb-5">
        {{ dynamicMainText }}
      </h1>
    </div>

    <div class="categories-list-wrapper justify-content-center w-100 mt-45 d-none d-sm-none d-md-flex">
      <a v-for="(item, index) in stylesByType[type]" 
        :key="index" 
        class="categories-list-item categories-list-item-style text-center"
        :class="categoryClass(item)"
        :href="hrefStyle(item)"
      >
        <div class="decoration d-flex align-items-end">
        </div>

        <div class="bottom-text">
          {{ item.name }}
        </div>
      </a>
    </div>

    <div class="categories-list-wrapper justify-content-center mt-45 just-carousel couture-items-carousel d-md-none">
      <a v-for="(item, index) in stylesByType[type]" 
        :key="index" 
        class="carousel-item categories-list-item categories-list-item-style text-center"
        :class="categoryClass(item)"
        :href="hrefStyle(item)"
      >
        <div class="decoration d-flex align-items-end">
        </div>

        <div class="bottom-text">
          {{ item.name }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TypeStyle',
  props: {
    type: {
      type: String,
      required: true
    },
    mainText: {
      type: String,
      optional: true
    },
    subText: {
      type: String,
      optional: true
    },
    btnText: {
      type: String,
      optional: true
    }
  },
  computed: {
    dynamicMainText() {
      if (this.mainText) {
        return this.mainText;
      }

      return `Rent ${this.type} By Style`
    }
  },
  data() {
    return {
      stylesByType: {
        bracelets: [
          { name: 'TENNIS BRACELETS', value: 'tennis' },
          { name: 'DIAMOND BRACELETS', value: 'diamond' } ,
          { name: 'COUTURE BRACELETS', value: 'couture' }, 
          { name: 'SILVER BOUTIQUE BRACELETS', value: 'silver-boutique' } 
        ],
        rings: [
          { name: 'DIAMOND COCKTAIL RINGS', value: 'diamond-cocktail' },
          { name: 'GEMSTONE COCKTAIL RINGS', value: 'gemstone-cocktail' },
          { name: 'COUTURE RINGS', value: 'couture' },
          { name: 'STACKABLE RINGS', value: 'stackable' }
        ],
        necklaces: [
          { name: 'DIAMOND NECKLACES', value: 'diamond' },
          { name: 'COUTURE NECKLACES', value: 'couture' },
          { name: 'PENDANT NECKLACES', value: 'pendant' },
          { name: 'CHOKER NECKLACES', value: 'choker' }
        ],
        earrings: [
          { name: 'DIAMOND EARRINGS', value: 'diamond' },
          { name: 'DROP EARRINGS', value: 'drop' },
          { name: 'COUTURE EARRINGS', value: 'couture' },
          { name: 'HOOP EARRINGS', value: 'hoop' }
        ]
      }
    }
  },
  mounted() {
    $(".just-carousel").slick({
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 2,
      })
  },
  methods: {
    hrefStyle(item) {
      return `/rent-jewelry?type=${this.type}&style=${item.value}`
    },
    categoryClass(item) {
      return `categories-list-item-style-${this.type}--${item.value}`
    }
  }
  
}
</script>

<style scoped>
.main-text {
  text-transform: capitalize;
}

/deep/ .slick-slide {
  margin: 0 10px;
}
</style>