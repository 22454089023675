import Vue from "vue/dist/vue.esm";
import JsonApi from "../../../vendor/json-api";

import VendorsAccountProfile from '../../components/vendors/account/profile';
import VendorsAccountStore from '../../components/vendors/account/store';
import VendorsAccountCompany from '../../components/vendors/account/company';

export default class VendorsProfile {
  account() {
    var app = new Vue({
      el: '#vendor-profile-form',
      components: {
        VendorsAccountProfile,
        VendorsAccountStore,
        VendorsAccountCompany
      },
      data: {
        vendor: JsonApi.deserialize(gon.payload.vendor),
        account: JsonApi.deserialize(gon.payload.account),
        store: JsonApi.deserialize(gon.payload.store),
        company: JsonApi.deserialize(gon.payload.company)
      }
    })
  }
}
