
import Vue from "vue/dist/vue.esm"
import JsonApi from "../../../vendor/json-api"
import VendorsOrdersList from "../../components/vendors/orders/list"
import ReviewModal from "../../components/vendors/orders/review_modal"
import ShowReviewModal from "../../components/vendors/orders/show_review_modal"

export default class VendorsOrders {
  fulfillment() {
    new Vue({
      components: {
        VendorsOrdersList, ReviewModal, ShowReviewModal
      },
      el: '#app-orders',
      data: {
        reviewPath: null,
        currentOrderItemId: null,
        showVendorReview: {},
        sub_orders: JsonApi.deserialize(gon.sub_orders).data
      },
      methods: {
        updateSubOrder(updated_sub_order) {
          let index = this.sub_orders.findIndex(sub_order => {
            return sub_order.id == updated_sub_order.id
          })
          this.sub_orders.splice(index, 1, updated_sub_order)
        },
        updateOrderItem(updated_order_item) {
          let that = this

          this.sub_orders.forEach(function(sub_order, sub_order_index) {
            sub_order.order_items.data.forEach(function(order_item, order_item_index){
              if(order_item.id == updated_order_item.id) {
                that.sub_orders[sub_order_index].order_items.data.splice(order_item_index, 1, updated_order_item)
              }
            })
          })
        },
        showReviewModal(review) {
          this.showVendorReview = review
        },
        reviewModal(path, id) {
          this.reviewPath = path
          this.currentOrderItemId = id
        },

        reviewCreated(new_vendor_revue, order_item_id){
          let that = this
          let deserializedVendorReview = JsonApi.deserialize(new_vendor_revue)

          that.sub_orders.forEach(function(sub_order, sub_order_index){
           sub_order.order_items.data.forEach(function(order_item, order_item_index){
              if(that.currentOrderItemId == order_item.id) {
                that.sub_orders[sub_order_index].order_items.data[order_item_index]['vendor_review']['data'] = deserializedVendorReview
              }
            });
          });
        }
      }
    })
  }

  submitted() {
    this.fulfillment()
  }

  sent() {
    this.fulfillment()
  }

  history() {
    this.fulfillment()
  }

  show() {
    this.fulfillment()
  }
}
