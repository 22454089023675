import Vue from "vue/dist/vue.esm";
import JsonApi from "../../vendor/json-api";

import Wizard from '../components/subscriptions/wizard';

export default class Subscriptions {
  index() {
    var app = new Vue({
      el: '#subscriptions-wizard',
      components: { Wizard },
      data: {
        account: JsonApi.deserialize(gon.payload.account),
        shippingAddress: JsonApi.deserialize(gon.payload.shipping_address),
        billingAddress: JsonApi.deserialize(gon.payload.billing_address),
      },
      methods: {
        setAccount(data) {
          this.account = data;
        },
        setAddress(data) {
          if (data.address_type == 'shipping') {
            this.shippingAddress = data
          }
          if (data.address_type == 'billing') {
            this.billingAddress = data
          }
        }
      }
    })
  }
}
