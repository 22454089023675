require.context("../images/app", true, /\.(gif|jpg|jpeg|png|svg|webp)$/i)
require.context("../images/fallback", true, /\.(gif|jpg|jpeg|png|svg|webp)$/i)
require.context("../videos", true, /\.(mp4)$/i)

import "bootstrap";
import '@fortawesome/fontawesome-free/css/all';
import '@fortawesome/fontawesome-free/js/all';


import "../stylesheets/app.scss"

// Datepicker
import 'gijgo';

import * as Application from "../javascripts/app/application";

Application.run()
